import React, { useState } from 'react';
import DatePicker from 'react-mobile-datepicker';
import styled from 'styled-components';
import { Colors } from 'src/constants';

const monthMap = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

const dateConfig = {
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
  month: {
    format: value => monthMap[value.getMonth() + 1],
    caption: 'Mon',
    step: 1,
  },
  date: {
    format: 'DD',
    caption: 'Day',
    step: 1,
  },
};

const SelectDateButton = styled.button`
  cursor: pointer;
  flex: 50%;
  border: none;
  border-bottom: 1px solid ${Colors.DARK};
  padding: 10px;
  background-color: ${Colors.TERTIARY_ORANGE};
  text-align: left;
`;

const Datepicker = ({ date, setDate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = date => {
    setDate(date);
    setIsOpen(false);
  };

  const handleCancel = date => {
    setIsOpen(false);
  };

  return (
    <>
      <SelectDateButton onClick={() => setIsOpen(true)}>
        {date ? (
          <b>
            {new Date(date).toLocaleDateString('en-IN', {
              dateStyle: 'medium',
            })}
          </b>
        ) : (
          'Date of birth *'
        )}
      </SelectDateButton>
      <DatePicker
        // To set minimum age to be 13
        max={new Date(new Date().setFullYear(new Date().getFullYear() - 13))}
        value={date}
        isPopup={true}
        isOpen={isOpen}
        onSelect={handleSelect}
        onCancel={handleCancel}
        confirmText="Ok"
        cancelText="Cancel"
        showHeader={false}
        dateConfig={dateConfig}
      />
    </>
  );
};

export default Datepicker;
