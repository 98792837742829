import React from "react";
import styled from "styled-components";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
`;

const LoaderSpan = styled.span`
  border: ${(props) => props.size * (16 / 120)}px solid #f3f3f3;
  border-radius: 50%;
  border-top: ${(props) => props.size * (16 / 120)}px solid #f15223;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 3px;
  display: ${(props) => props.display || ""};
`;

const Loading = ({ size, display }) => (
  <LoaderContainer>
    <div>
      <LoaderSpan size={size} display={display} />
    </div>
  </LoaderContainer>
);

export default Loading;
