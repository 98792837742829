import { API, UPLOAD_API } from 'src/helpers/api';

// export const addPortfolio = ({ payload, config }) => {
//   return UPLOAD_API.post('/add-portfolio', payload, config);
// };

export const addPortfolio = ({ payload, config }) => {
  return UPLOAD_API.post('/add-portfolio-new', payload, config);
};

export const getPortfolio = ({ payload: { start, type }, cancelToken }) => {
  return API.get(`/toiler-portfolio?start=${start}&type=${type}`, {
    cancelToken,
  });
};

export const deletePortfolio = ({ mediaId }) => {
  return API.get(`/delete-portfolio/${mediaId}`);
};
