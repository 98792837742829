/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './Footer.css';
import TextField from '@mui/material/TextField';

import FooterLogo from 'src/asset/img/logo.png';
import AntoilerWhite from 'src/asset/img/antoiler-white.png';
import Mail from 'src/asset/img/mail.png';
import Call from 'src/asset/img/call.png';
import Facebook from 'src/asset/img/facebook.svg';
import Linkedin from 'src/asset/img/linkedin.svg';
import Instagram from 'src/asset/img/instagram.svg';
import Whatsapp from 'src/asset/img/whatsapp.svg';
import Google from 'src/asset/img/google.svg';
import Youtube from 'src/asset/img/youtube.svg';
import Twitter from 'src/asset/img/twitter.svg';
import {SaveContact} from 'src/services'; 
function Footer() {
  // Handle Form Submit

  
  const [FormErr,setFormErr] = React.useState({
    name:      {err:false,helpTxt:""},
    email:     {err:false,helpTxt:""},
    msg:       {err:false,helpTxt:""},
    subject:   {err:false,helpTxt:""},
  })
  const [response,setResponse] = React.useState({cls:'',msg:''});


  const HandleSubmit = async e => {
    e.preventDefault();
    let name          = e.target.name.value;
    let email         = e.target.email.value;
    let msg           = e.target.msg.value;
    let subject       = e.target.subject.value;

    let isFormValid = 1;
    if(!name){
      setFormErr(oldVal => ({...oldVal,name:{err:true,helpTxt:'Please Fill Your Name'}}))
      isFormValid=0;
    }

    if(!email){
      setFormErr(oldVal => ({...oldVal,email:{err:true,helpTxt:'Please Fill Your Email'}}))
      isFormValid=0;
    }

    if(!msg){
      setFormErr(oldVal => ({...oldVal,msg:{err:true,helpTxt:'Please Fill Your Message'}}))
      isFormValid=0;
    }
    if(!subject){
      setFormErr(oldVal => ({...oldVal,subject:{err:true,helpTxt:'Please Fill Your Subject'}}))
      isFormValid=0;
    }
    
    if(isFormValid){
      let SendObj = {name:name,email:email, msg:msg,subject:subject}
      let res     = await SaveContact(SendObj);
      console.log(res)
      if(res.statusText==='OK'){
        if(res.data.status){
          setResponse({cls:'alert alert-success',msg:res.data.msg})
          e.target.reset();
          HideMesg();
          return;
        }
      }
      setResponse({cls:'alert alert-danger',msg:'Something went Wrong Please Try Again'})
      HideMesg();
    }
    e.preventDefault();
  };

  const HideMesg = ()=>{
    setTimeout(() => {
      setResponse({cls:'',msg:''})
    }, 4000);
  }

  const handlerChange  = (e)=>{
    let targetName = e.target.name;
    let targetVal = e.target.value;
    if(targetVal){
      setFormErr(oldVal => ({...oldVal,[targetName]:{err:false,helpTxt:``}}))
    }else{
      setFormErr(oldVal => ({...oldVal,[targetName]:{err:true,helpTxt:`Please Fill Your ${targetName}`}}))
    }
  }




  return (
    <>
      <div className="ant-footer element" id="contact" name="contact">
        <div className="footer-left">
          <img className="footer-logo" src={AntoilerWhite} alt="Anotiler-White" />
          <p className="footer-about">
          Your toiling to find professionals for day-to-day work ends here at Antoiler. Antoiler is a one-stop solution to find nearby skilled professionals based on their work and reviews. Find nearby plumbers, electricians, and technicians at your doorstep.
          </p>
          <div className="contact-details">
            <p className="contact-heading">Contact Details:</p>
            <p className="contact-email">
              <img src={Mail} alt="Antoiler-Email"  />{' '}
              <a href="mailto:iamantoiler@gmail.com"> iamantoiler@gmail.com</a>
            </p>
            <p className="contact-number">
              <img src={Call} alt="Antoiler-Number" /> <a href="tel:07514001332">0751 400 1332</a>
            </p>
          </div>
          <div className="social-links">
            <ul>
              <li>
                <a href="https://www.facebook.com/iamantoiler" target="_blank">
                  <img
                    className="facebook-icon"
                    src={Facebook}
                    alt="fackbook"
                  />
                </a>
              </li>
              <li>
                <a href="https://instagram.com/antoiler" target="_blank">
                  <img
                    className="instagram-icon"
                    src={Instagram}
                    alt="instagram"
                  />
                </a>
              </li>
              <li>
                <a href="https://wa.me/919301797108" target={'_blank'} >
                  <img
                    className="whatsapp-icon"
                    src={Whatsapp}
                    alt="whatsapp"
                  />
                </a>
              </li>
              <li>
                <a href="mailto:iamantoiler@gmail.com">
                  <img className="google-icon" src={Google} alt="google" />
                </a>
              </li>
              <li>
                <a href="mailto:iamantoiler@gmail.com">
                  <img className="google-icon" src={Linkedin} alt="google" />
                </a>
              </li>

              {/* <li>
                <a href="">
                  <img className="youtube-icon" src={Youtube} alt="youtube" />
                </a>
              </li> */}
              <li>
                <a href="https://twitter.com/antoiler" target={'_blank'}>
                  <img className="twitter-icon" src={Twitter} alt="twitter" />
                </a>
              </li>
            </ul>
            {/* <span><a href=""><img className='facebook-icon' src={Facebook} alt='fackbook' /></a></span>
                <span><a href=""><img className='instagram-icon' src={Instagram} alt='instagram' /></a></span>
                <span><a href=""><img className='whatsapp-icon' src={Whatsapp} alt='whatsapp' /></a></span>
                <span><a href=""><img className='google-icon' src={Google} alt='google' /></a></span>
                <span><a href=""><img className='youtube-icon' src={Youtube} alt='youtube' /></a></span>
                <span><a href=""><img className='twitter-icon' src={Twitter} alt='twitter' /></a></span> */}
          </div>
        </div>
        <div className="footer-right">
          <span className="heading">Get in Touch!</span>
          <div className="ant-contact-form">
            <form method="POST" onSubmit={HandleSubmit}>
              <div className="row row-space">
                <div className="col-2">
                  <div className="input-group">
                      <TextField
                        
                        margin="dense"
                        id="name"
                        label="Your name"
                        type="text"
                        fullWidth
                        variant="standard"
                        autoComplete="off"
                        name="name"
                        error={FormErr.name.err}
                        onBlur={handlerChange}
                        helperText={FormErr.name.helpTxt}
                      />
                  </div>
                </div>
                <div className="col-2">
                  <div className="input-group">
                  <TextField
                    margin="dense"
                    id="email"
                    label="Your email"
                    type="email"
                    fullWidth
                    variant="standard"
                    autoComplete="off"
                    name="email"
                    error={FormErr.email.err}
                    onBlur={handlerChange}
                    helperText={FormErr.email.helpTxt}
                  />
                  </div>
                </div>
              </div>
              <div className="input-group">
              <TextField
              margin="dense"
              id="subject"
              label="Your subject"
              type="text"
              fullWidth
              variant="standard"
              autoComplete="off"
              name="subject"
              error={FormErr.subject.err}
              onBlur={handlerChange}
              helperText={FormErr.subject.helpTxt}
            />
              </div>

              <div className="">
                {/* <textarea
                  className="input--style-2 text-area"
                  rows={3}
                  placeholder="Type Your Message"
                  name="msg"
                ></textarea> */}

            <TextField
              
              margin="dense"
              id="msg"
              rows={3}
              label="Adddress"
              type="textarea"
              fullWidth
              variant="standard"
              name="msg"
              autoComplete="off"
              error={FormErr.msg.err}
              onBlur={handlerChange}
              helperText={FormErr.msg.helpTxt}
              
            />

              </div>
              <div className="p-t-30">
              <div className={response.cls+ ` form-response`} >{response.msg}</div>
              <div className='modal-footer' >
                <button className="btn btn--radius" type="submit">
                  Send Message
                </button>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="ant-bottom-footer">
        <span>
          <a href="/privacy-policy" >Terms & Conditions</a>
        </span>
        <span>All Right Reserved &copy; 2022, Antoiler</span>
        <span>
          <a href="https://techcarrel.com" target="_blank">TechCarrel LLP</a>
        </span>
      </div>
      {/* <Bottomfooter /> */}
    </>
  );
}

export default Footer;
