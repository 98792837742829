import React from 'react';
import styled from 'styled-components';
import { Colors } from 'src/constants';
import { LoadingSpinner } from 'src/StyledComponents';

const getBackgroundColor = props => {
  if (props?.secondary) return Colors.SECONDARY_ORANGE;

  if (props?.tertiary) return Colors.BACKGROUND_GRAY;

  if (props.dark) return Colors.DARK;

  if (props.outline || props.link) return 'transparent';

  return Colors.PRIMARY_ORANGE;
};

const getTextColor = props => {
  if (props?.outline || props.secondary) return Colors.BLACK;
  if (props.link) {
    if (props.noColor) return Colors.BACKGROUND_GRAY;
    if (props.primary) return Colors.PRIMARY_ORANGE;
    if (props.neon) return Colors.NEON_BLUE;
    return Colors.LINK_BLUE;
  }
  return Colors.WHITE;
};

const getFontSize = props => {
  if (props.tiny) return '10px';
  if (props.medium) return '12px';
  if (props.small) return '13px';
  return '14px';
};

const getPadding = props => {
  if (props.padding) return props.padding;
  if (props.noPadding) return '0px';
  if (props.small) return '8px 20px';
  if (props.link) return '4px';
  if (props.tiny) return '5px 10px';
  return '14px 28px';
};

const getActiveBackgroundColor = props => {
  if (props.noColor) return 'lightgray';
  else if (props.primary) return Colors.PRIMARY_ORANGE;
  else if (props.dark) return 'white';
  else return '';
};

const getActiveTextColor = props => {
  if (props.noColor || props.primary) return 'white';
  else if (props.dark) return 'black';
};

const getActiveBorder = props => {
  if (props.dark) return '1px solid black';
};

const StyledButton = styled.button`
  position: relative;
  pointer-events: ${props => props?.loading && 'none'};
  background: ${props => getBackgroundColor(props)};
  color: ${props => getTextColor(props)};
  border-radius: 5px;
  padding: ${props => getPadding(props)};
  font-size: ${props => getFontSize(props)};
  border: ${props =>
    props.outline
      ? `1px solid ${
          props.noColor ? Colors.BACKGROUND_GRAY : Colors.PRIMARY_ORANGE
        }`
      : 'none'};
  cursor: pointer;
  width: ${props => (props.fluid ? '100%' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &[disabled] {
    background: ${props =>
      props.link ? 'transparent' : Colors.BACKGROUND_GRAY};
    pointer-events: none;
  }
  &:active {
    background-color: ${props => getActiveBackgroundColor(props)};
    color: ${props => getActiveTextColor(props)};
    border: ${props => getActiveBorder(props)};
  }
`;

const Image = styled.img`
  width: ${props => (props.small ? 10 : 15)}px;
`;

const ButtonText = styled.span`
  font-size: inherit;
  transition: opacity 200ms;
  transition-delay: ${({ loading }) => (loading ? '0ms' : '200ms')};
  opacity: ${({ loading }) => (loading ? 0 : 1)};
  white-space: nowrap;
  ${props => props.underlined && `text-decoration: underline;`}
`;

const Button = ({
  children,
  loading = false,
  disabled = false,
  underlined,
  onClick = () => {},
  ...props
}) => (
  <StyledButton
    {...props}
    loading={loading}
    onClick={onClick}
    disabled={disabled || loading}
    // TO DISABLE OPTION
    onContextMenu={event => event.preventDefault()}
  >
    {loading ? <LoadingSpinner {...props} loading={loading} /> : null}
    {!loading && props.icon && (
      <Image
        src={props.icon}
        small={props.small}
        tiny={props.tiny}
        alt="btn-icon"
      />
    )}
    {children && (
      <ButtonText underlined={underlined} loading={loading}>
        {children}
      </ButtonText>
    )}
  </StyledButton>
);

export default Button;
