export const Colors = {
  PRIMARY_ORANGE: '#F15A24',
  SECONDARY_ORANGE: '#FFDFAC',
  TERTIARY_ORANGE: '#FFE7C2',
  LINK_BLUE: '#006FFF',
  LINK_LIGHT_BLUE: '#00B9FF',
  NEON_BLUE: '#00B9FF',
  WHITE: '#fff',
  BLACK: '#000',
  DARK: '#2E2E2E',
  DARK_BROWN: '#6b5025',
  BROWN: '#a8844a',
  BOTTOMPOPUP_BG: '#F9F9F9',
  OVERLAY_BG: '#0000007f',
  PROGRESS_GREEN: '#00FF3C',
  BACKGROUND_GRAY: '#CCCCCC',
  LIGHT_GRAY: '#707070',
  LIGHT_GREY: '#5A5A5A',
  LIGHT_GREY_A90: '#5a5a5a90',
  LIGHT_BG_GRAY: '#F6F6F6',
  LIGHT_GREEN: '#07FF5A',
  PROFILE_BG: '#fcfcfc',
  SEARCH_RESULTS_BG: '#F8F8F8',
  DASHBOARD_HEADER_FONT: '#BF9E6A',
  HR_BG_COLOR: '#DEDEDE',
};
