import React from 'react';
import { Flex } from 'src/StyledComponents';
import RenderSmoothImage from '../RenderSmoothImage';
import { TabsContainer, Tab } from './StyledComponents';

const Tabs = ({
  options,
  value,
  onChange,
  highlightActive,
  iconSize,
  tabStyle = {},
}) => {
  return (
    <TabsContainer>
      {options.map(option => (
        <Tab
          style={tabStyle}
          highlightActive={highlightActive}
          key={option.value}
          sticky={option?.sticky ?? false}
          active={value === option.value}
          onClick={() => {
            onChange(option.value);
            document.getElementById(`option-${option.value}`)?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            });
          }}
        >
          {option.icon ? (
            <Flex gap={10} row id={`option-${option.value}`} centered>
              <RenderSmoothImage
                src={option.icon}
                alt={'img'}
                width={iconSize || '15px'}
                height={iconSize || '15px'}
                containerStyle={{
                  width: 'max-content',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: 'none',
                }}
              />
              <p>{option.label}</p>
            </Flex>
          ) : (
            option.label
          )}
        </Tab>
      ))}
    </TabsContainer>
  );
};

export default Tabs;
