import styled from 'styled-components';

export const OptionContainer = styled.div`
  border-width: 0.5px 0px 0px 0px;
  border-style: solid;
  border-color: cccccc;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

// REACT_SELECT_STYLE
export const customStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    width: '100%',
    borderRadius: '4px 4px 0px 0px',
    outline: 'none',
    border: '1px solid #cccccc',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #cccccc',
    },
  }),
  menu: styles => ({
    ...styles,
    borderRadius: '0px 0px 4px 4px',
    top: 'auto',
    margin: 0,
    left: '1px',
    width: 'calc(100% - 2px)',
    padding: 0,
    borderWidth: '0px',
    zIndex: 6,
  }),
  menuList: styles => ({
    ...styles,
    borderRadius: '0px 0px 4px 4px',
    margin: 0,
    padding: 0,
    borderWidth: '0px',
  }),
  input: base => ({
    ...base,
    minWidth: '150px',
    '& > input': {
      minWidth: '190px !important',
    },
  }),
};
