import React, { useCallback } from 'react';
import { getProfessionOptions } from 'src/services/user';
import { Select } from 'src/components';
import CategoryIcon from 'src/asset/images/category.png';
import { debounce } from 'src/helpers/debounce';

const ProfessionSelect = ({
  value: selectedOption,
  onChange: setSelectedOption,
  placeholder = 'Select Profession',
  noTitle = false,
  width,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce(async (data, callback) => {
      if (data.length > 1) {
        const res = await getProfessionOptions(data);
        if (res.status === 200) {
          const options =
            res.data.filterd_category.map(option => ({
              value: option.cat_id,
              label: option.cat_title,
              icon: option.cat_img,
            })) || [];
          callback(options);
        }
      }
    }, 300),
    []
  );

  return (
    <>
      
      <div style={{ margin: noTitle ? '0' : '20px 0px', width }}>
        <Select
          icon={CategoryIcon}
          value={selectedOption}
          handleChange={setSelectedOption}
          placeholder={placeholder}
          onSearch={debounceSearch}
        />
      </div>
    </>
  );
};

export default ProfessionSelect;
