import styled from 'styled-components';
import { Colors } from 'src/constants';

export const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const LogoContainer = styled.div`
  height: ${props => (props.compactLogo ? '10%' : '40%')};
  min-height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const FormContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: scroll;
  flex-direction: column;
  align-items: center;
  padding: 35px 20px 20px 20px;
  background-color: ${Colors.TERTIARY_ORANGE};
  border-radius: 30px 30px 0 0;
  gap: 5px;
`;

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: 33px;
  left: 25px;
  width: 0.6rem;
`;
