import React, { useMemo, useRef, useState } from 'react';
import Button from '../Button';
import Modal from '../Modal';
import styled from 'styled-components';
import Webcam from 'react-webcam';
import CameraIcon from 'src/asset/images/camera.svg';
import SwitchIcon from 'src/asset/images/switch.png';
import { Flex } from 'src/StyledComponents';
import { toast } from 'react-toastify';
import { toastOptions } from 'src/constants';

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  height: 50px;
`;

const Preview = styled.img`
  width: 80vw;
  height: 100%;
  object-fit: contain;
`;

const Camera = props => {
  const { onCapture = () => {} } = props;
  const webcamRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const facingMode = {
    USER: 'user',
    ENVIRONMENT: 'environment',
  };
  const [mode, setMode] = useState(facingMode.ENVIRONMENT);

  const videoConstraints = useMemo(
    () => ({
      width: 1280,
      height: 720,
      facingMode: mode,
    }),
    [mode]
  );

  const toggleModal = () => {
    reset();
    setOpen(prev => !prev);
  };

  const toggleMode = () => {
    setMode(prev =>
      prev === facingMode.USER ? facingMode.ENVIRONMENT : facingMode.USER
    );
  };

  const takePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  };

  const reset = () => {
    setImgSrc(null);
  };

  const onSubmitHandler = () => {
    onCapture(imgSrc);
    toggleModal();
  };

  return (
    <div style={{ width: '100%' }}>
      <Button
        secondary
        icon={CameraIcon}
        onClick={toggleModal}
        style={{ padding: '12px 5px', width: '100%', fontSize: '10px' }}
      >
        Take a Picture
      </Button>
      <Modal
        show={open}
        onClose={toggleModal}
        modalHeight="90%"
        title="Preview"
      >
        {open && (
          <Flex gap={10} height="100%">
            {imgSrc ? (
              <>
                <div style={{ height: 'calc(100% - 60px)' }}>
                  <Preview src={imgSrc} alt="img" />
                </div>
                <ButtonGroup>
                  <Button outline small icon={CameraIcon} onClick={reset}>
                    Retake
                  </Button>
                  <Button small onClick={onSubmitHandler}>
                    Continue
                  </Button>
                </ButtonGroup>
              </>
            ) : (
              <>
                <div style={{ height: 'calc(100% - 60px)' }}>
                  <Webcam
                    style={{ objectFit: 'contain' }}
                    height="100%"
                    width="100%"
                    screenshotQuality={1}
                    mirrored={mode === facingMode.USER}
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    forceScreenshotSourceSize={true}
                    imageSmoothing={false}
                    videoConstraints={videoConstraints}
                    onUserMediaError={e => {
                      toast.error('Permission Denied', toastOptions);
                    }}
                  />
                </div>
                <ButtonGroup>
                  <Button
                    outline
                    small
                    icon={CameraIcon}
                    onClick={takePhoto}
                  ></Button>
                  <Button
                    outline
                    small
                    icon={SwitchIcon}
                    onClick={toggleMode}
                  ></Button>
                </ButtonGroup>
              </>
            )}
          </Flex>
        )}
      </Modal>
    </div>
  );
};

export default Camera;
