import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import AppRouter from './routes';
import { ToastContainer } from 'react-toastify';
import { ContentProvider } from './helpers/store';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import CheckDialedUser from './components/CheckDialedUser';
import { debounce } from './helpers/debounce';
import {
  getBrowserName,
  getIpAddress,
  getOsName,
  saveDownloadLog,
} from './services/app';
import LandingPage from './pages/landing-page/LandingPage';

import ReactGA from 'react-ga';
import { getUserFromStorage } from './helpers/storage';
import DesktopRoutes from './routes/DesktopRoutes';

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  @media (min-width: 500px) {
    max-width: 500px;
  }
  @media (min-width: 768px) {
    max-width: 100%;
  }
`;

const TRACKING_ID = 'G-LXSTRN9F70'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const ALLOW_SCREEN = 'portrait-primary';
  const [deviceSupport, setDeviceSupport] = useState(
    window?.screen?.orientation?.type
      ? window.screen.orientation.type === ALLOW_SCREEN
      : window.matchMedia('(orientation: portrait)').matches
  );

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    window.addEventListener('appinstalled', e => {
      // integrate the api to track count of PWA installs

      getIpAddress()
        .then(ip => {
          const OSName = getOsName();
          const browserName = getBrowserName();
          const user = getUserFromStorage();
          const address =
            user.address || `lat: ${user.lat}, long: ${user.long}`;

          saveDownloadLog({
            internet_ip: ip,
            location: address,
            browser: browserName,
            os: OSName,
          }).then(console.log);
        })
        .catch(e => console.log(e));
    });
    if (window?.screen?.orientation?.type) {
      window?.screen?.orientation?.addEventListener('change', detectChange);
    } else {
      window.addEventListener('resize', debouncedDetechSafariChange);
    }

    return () => {
      window?.screen?.orientation?.removeEventListener('change', detectChange);
      window?.removeEventListener('resize', debouncedDetechSafariChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detectChange = useCallback(() => {
    if (window?.screen?.orientation?.type !== ALLOW_SCREEN) {
      setDeviceSupport(false);
    } else {
      setDeviceSupport(true);
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDetechSafariChange = useCallback(
    debounce(() => {
      var mql = window.matchMedia('(orientation: portrait)');
      if (mql.matches) {
        setDeviceSupport(true);
      } else {
        setDeviceSupport(false);
      }
    }, 500),
    []
  );

  return (
    <Router>
      <ContentProvider>
        <AppContainer className="App">
          {deviceSupport ? <AppRouter /> : <DesktopRoutes />}
          <ToastContainer />
          <CheckDialedUser />
        </AppContainer>
      </ContentProvider>
    </Router>
  );
};

export default App;
