import React from 'react';
import { Colors } from 'src/constants';
import { Flex } from 'src/StyledComponents';
import styled from 'styled-components';

const Item = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  border-radius: 2px;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
  border: 1px solid ${Colors.DARK};
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 2.5px;
  &:hover ~ ${RadioButtonLabel} {
    background: ${Colors.DARK};
    &::after {
      content: '';
      display: block;
      border: 1px solid ${Colors.WHITE};
      border-radius: 50%;
      width: 80%;
      height: 80%;
    }
  }
  &:checked + ${Item} {
    background: ${Colors.PRIMARY_ORANGE};
    border: 2px solid ${Colors.DARK};
  }
  &:checked + ${RadioButtonLabel} {
    background: ${Colors.PRIMARY_ORANGE};
    border: 1px solid ${Colors.DARK};
    &::after {
      content: '';
      display: block;
      border: 1px solid ${Colors.WHITE};
      border-radius: 50%;
      width: 80%;
      height: 80%;
      /* margin: 1px; */
    }
  }
`;

const RadioGroup = ({ config = [], value, onChange = () => {} }) => {
  return (
    <Flex row justifyBetween gap={40}>
      {config.map(item => {
        return (
          <Item key={`radio-${item.label}`}>
            <RadioButton
              type="radio"
              name="radio"
              id={`radio-${item.label}`}
              value={item.value}
              checked={value === item.value}
              onChange={e => onChange(e.target.value)}
            />
            <RadioButtonLabel />
            <label htmlFor={`radio-${item.label}`}>{item.label}</label>
          </Item>
        );
      })}
    </Flex>
  );
};

export default RadioGroup;
