import React from 'react';
import ReactSelect from 'react-select/async';
import SearchIcon from 'src/asset/images/SearchGray.png';
import { baseURL } from 'src/helpers/api';
import RenderSmoothImage from '../RenderSmoothImage';
import { customStyle, OptionContainer } from './StyledComponents';

const Select = ({
  placeholder,
  options,
  value,
  handleChange,
  onSearch,
  icon,
}) => {
  const CustomOption = props => {
    const { label, innerRef, innerProps, data } = props;
    return (
      <OptionContainer ref={innerRef} {...innerProps}>
        {icon ? (
          <div style={{ width: '25px', height: '25px' }}>
            <RenderSmoothImage
              src={data.icon ? baseURL + data.icon : icon}
              alt="icon"
              width="20"
              height="20"
            />
          </div>
        ) : null}
        <p style={{ width: '100%' }}>{label}</p>
      </OptionContainer>
    );
  };

  return (
    <ReactSelect
      styles={customStyle}
      components={{
        Option: CustomOption,
        DropdownIndicator: () => (
          <div style={{ padding: '10px' }}>
            <img src={SearchIcon} alt="search" />
          </div>
        ),
        IndicatorSeparator: () => <></>,
      }}
      placeholder={placeholder}
      value={value}
      isClearable
      isSearchable
      onChange={value => handleChange(value)}
      loadOptions={onSearch}
    />
  );
};

export default Select;
