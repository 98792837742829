import { Colors } from 'src/constants';
import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  ${props =>
    !props.scroll
      ? `
        min-width: 100%;
        width: max-content;
      `
      : `
        width: 100%;
        overflow: scroll;
      `}
`;

export const Tab = styled.div`
  padding: 15px;
  border-bottom: ${props =>
    props.active ? '2px solid red' : '1px solid lightgray'};
  cursor: pointer;
  width: 100%;
  min-width: max-content;
  text-align: center;
  color: ${props =>
    props.active && props.highlightActive
      ? Colors.PRIMARY_ORANGE
      : Colors.DARK};
  font-weight: ${props => (props.active ? 600 : 400)};
  background: white;
  ${props => props?.sticky && 'position: sticky;left: 0;z-index: 1;'}
  &:active {
    background-color: ${Colors.SECONDARY_ORANGE};
    border-radius: 5px;
  }
`;
