import React from "react";
import styled from "styled-components";

const StyledDivider = styled.h4`
  &:after {
    content: "";
    width: 3rem;
    border: 1px solid #d4c3a9;
  }
  &:before {
    content: "";
    width: 3rem;
    border: 1px solid #d4c3a9;
  }

  padding: 14px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const Divider = ({ children, ...props }) => (
  <StyledDivider {...props}>OR</StyledDivider>
);

export default Divider;
