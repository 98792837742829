/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './About.css';
import { Link } from 'react-scroll';

// const SideImg = require('../../../asset/img/side-antoiler.svg')

function About() {
  return (
    <div className="ant-about-section" id="About" name="About">
      <div className="ant-about-left">
        <div className="ant-about-heading">
          <span className="ant-heading">About App</span>
          <p className="ant-about-description">
            Your toiling to find professionals for day-to-day work ends here at
            Antoiler. Antoiler is a one-stop solution to find nearby skilled
            professionals based on their work and reviews. Contact directly and
            make your deal. Get nearby plumbers, electricians, and technicians
            at your doorstep.
          </p>
          <p className="ant-about-contact">
          <Link className="cursor-pointer" spy={true} to="contact" smooth={true}>
            Contact Us
            </Link>
          </p>
        </div>
      </div>
      <div className="ant-about-right">
{/* contenteditable="true" */}
      <span className='neo-text' >Antoiler</span>

      </div>
    </div>
  );
}

export default About;
