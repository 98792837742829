import React,{useState} from 'react'
// import Form from '../Form/Form';
import './Becomeantoiler.css'

function Becomeantoiler({popupisopen,togglepopup}) {

    
    // const [popupisopen, setpopupisopen] = useState(false);

    // function openPopup(){
    //     setpopupisopen(!popupisopen);
    // }

  return (
    <div className='ant-become-antoiler'>
        <div className='become-left'>
            <p className='become-main-text'>
                A New Name of Your Profession, Say Antoiler!
            </p>
            <p className='become-subtext'>
                Get yourself register today and make your own online presence.
            </p>
        </div>
        <div className='become-right'>
            <button className='become-button' onClick={togglepopup} >
                Become an Antoiler 
            </button>
        </div>
    </div>
  )
}

export default Becomeantoiler