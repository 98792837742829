import styled from 'styled-components';
import { Colors } from 'src/constants';

export const Label = styled.div`
  font: normal normal 600 14px/18px Montserrat;
  margin-bottom: 15px;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin: ${props => (props.noMargin ? '0px' : '5px 0')};
`;

export const StyledInput = styled.input`
  width: 100%;
  background-color: transparent;
  border-width: ${props => (props.outlined ? '0.5px' : '0px 0px 1px 0px')};
  border-style: solid;
  border-color: ${props => (props.outlined ? `#707070` : Colors.DARK)};
  border-radius: ${props => (props.outlined ? 5 : 0)}px;
  color: ${Colors.DARK};
  font-size: 14px;
  font-weight: 600;
  outline: none;
  padding: ${props => props.padding || (props.outlined ? '15px' : '10px')};
  padding-left: ${props => (props.prefix ? 20 : '')}px;

  ::placeholder {
    color: ${Colors.DARK};
    font-weight: 400;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  resize: vertical;
  background-color: transparent;
  border-width: ${props => (props.outlined ? '0.5px' : '0px 0px 1px 0px')};
  border-style: solid;
  border-color: ${props => (props.outlined ? `#707070` : Colors.DARK)};
  border-radius: ${props => (props.outlined ? 5 : 0)}px;
  color: ${Colors.DARK};
  font-size: 14px;
  font-weight: 400;
  outline: none;
  min-height: 50px;
  padding: ${props => (props.outlined ? 15 : 10)}px;

  ::placeholder {
    color: ${Colors.LIGHT_GRAY};
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: ${props => (props.prefix ? 'auto' : '8px')};
  left: ${props => (props.prefix ? '8px' : 'auto')};
  top: 50%;
  transform: translateY(-50%);
  width: 9px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
