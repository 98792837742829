import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { Flex, Accordion, Card } from 'src/StyledComponents';
import RenderSmoothImage from '../RenderSmoothImage';
import { BottomPopup, Button, MediaPreview, StarRateView, TextArea } from '..';
import Comments from './Comments';
import DownIcon from 'src/asset/images/down-black.svg';
import { baseURL } from 'src/helpers/api';
import { markAsHelpful, reportUser, reviewReply } from 'src/services';
import { getUserType } from 'src/helpers/storage';
import { Colors } from 'src/constants';
import thumbsUp from 'src/asset/images/thumbs-up.svg';
import thumbsUpColored from 'src/asset/images/thumbs-up-colored.svg';
import { StoreContent } from 'src/helpers/store';

const Image = styled.div`
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  border: 1px solid #707070;
  border-radius: 5px;
  overflow: hidden;
`;

const Section = styled.h2`
  width: 100%;
  font-size: 14px;
`;

const HelperText = styled.p`
  width: 100%;
  font: normal normal normal 12px Montserrat;
  letter-spacing: 0px;
  color: ${props => (props.colored ? Colors.PRIMARY_ORANGE : '#3a3a3a')};
`;

const RejectionText = styled.p`
  width: 100%;
  font: normal normal 400 12px Poppins;
  text-align: ${props => (props.centered ? 'center' : 'left')};
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
`;

const RejectionTitle = styled.h4`
  width: 100%;
  text-align: left;
  font: normal normal bold 14px Poppins;
  font-weight: 600;
  letter-spacing: 0px;
  opacity: 1;
`;

const RejectionCard = styled.div`
  width: 100%;
  background: #ffd87d 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 10px 15px;
`;

function CommentCard(props) {
  const {
    reviewId,
    review_helpful,
    index,
    reviewer,
    replier,
    workSamples,
    helpCount,
    updateHelpful,
    dashboard,
    editReview,
    //   editReview =(review, cb) => {
    //   cb();
    // },

    declinedReason,
    // declinedReason = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting`,
  } = props;

  const {
    user: [user],
  } = useContext(StoreContent);

  // STATES
  const [show, setShow] = useState(false);
  const [updatingHelpful, setUpdatingHelpful] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [reportText, setReportText] = useState('');
  const [reply, setReply] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setReply(prev => ({ ...prev, [name]: value }));
  };

  const isSp = getUserType() === 'SP';

  const toggleShow = () => {
    setShow(prev => !prev);
  };

  const toggleHelpFul = () => {
    setUpdatingHelpful(true);
    return markAsHelpful(reviewId)
      .then(({ data }) => {
        if (data?.review_count)
          updateHelpful(index, helpCount + 1, data?.review_count);
        else updateHelpful(index, helpCount - 1, data?.review_count);
      })
      .finally(() => setUpdatingHelpful(false));
  };

  const report = cb => {
    const payload = {
      reported_user_id: reviewer?.id,
      message: reportText,
    };
    setLoading(true);
    reportUser(payload)
      .then(() => {
        setReportText('');
        cb();
      })
      .finally(() => setLoading(false));
  };

  const sendReply = cb => {
    const payload = {
      id: user.user_id,
      review_id: reply.id,
      message: reply.text,
      rating: reply.rating,
    };
    setLoading(true);
    reviewReply(payload)
      .then(() => {
        setReply({});
        cb();
      })
      .finally(() => setLoading(false));
    setReply({});
    cb();
  };

  const disablePublish =
    reply?.text === undefined || reply.text?.length === 0 || reply.rating === 0;

  return (
    <Card lightShadow>
      <Flex gap={10} height="max-content">
        {/* HEADER */}
        <Comments {...reviewer} editReview={editReview} reviewId={reviewId} />
        {/* SAMPLE WORK */}
        {workSamples?.length > 0 && (
          <>
            <Section>Samples of Work :</Section>
            <div style={{ width: '100%', overflow: 'auto' }}>
              <Flex row gap={10}>
                {workSamples.map((image, index) => {
                  const src = baseURL + (image?.thumb_img ?? image?.src);
                  return (
                    <Image
                      key={`work-done-image-${index}`}
                      onClick={() => setPreviewImage(image)}
                    >
                      <RenderSmoothImage objectFit={'cover'} src={src} />
                    </Image>
                  );
                })}
              </Flex>
            </div>
          </>
        )}
        {declinedReason ? (
          <RejectionCard>
            <RejectionTitle>Cause of Rejection</RejectionTitle>
            <RejectionText>{declinedReason}</RejectionText>
          </RejectionCard>
        ) : null}

        <Flex row justifyBetween>
          {!editReview && (
            <Flex row gap={5}>
              <img
                src={!isSp && !updateHelpful ? thumbsUpColored : thumbsUp}
                alt="thumbs-up"
                width={12}
              />
              <HelperText colored={!isSp && !updateHelpful}>
                {helpCount.toLocaleString('en-IN')} Found this Useful
              </HelperText>
            </Flex>
          )}
          {isSp && dashboard && (
            <BottomPopup
              title="Feedback"
              placeholder="Reply To This Feedback"
              trigger={props => (
                <Button
                  tiny
                  link
                  primary
                  {...props}
                  onClick={e => {
                    setReply({
                      id: reviewId,
                    });
                    props.onClick(e);
                  }}
                >
                  Reply To This Feedback
                </Button>
              )}
              closeCallback={() => setReply({})}
              bottomPopupContent={close => {
                return (
                  <Flex gap={20}>
                    <Flex height="max-content" gap={20}>
                      <StarRateView
                        label={'Rating'}
                        decimal
                        large={true}
                        rating={reply?.rating || 0}
                        name={'rating'}
                        onChange={handleChange}
                      />
                    </Flex>
                    <TextArea
                      placeholder="Enter your reply message here..."
                      outlined
                      name="reply"
                      onChange={e =>
                        setReply(p => ({ ...p, text: e.target.value }))
                      }
                      value={reply.text}
                      required
                      noMargin
                    />
                    <Button
                      fluid
                      loading={loading}
                      disabled={disablePublish}
                      onClick={() => sendReply(close)}
                    >
                      Send Reply
                    </Button>
                  </Flex>
                );
              }}
            />
          )}
        </Flex>
        {/* FOOTER */}
        {!editReview && !!updateHelpful && (
          <Flex row justifyBetween>
            {reviewer?.id !== user.user_id && (
              <Flex row gap={10}>
                <Button
                  tiny
                  loading={updatingHelpful}
                  outline
                  noColor
                  onClick={toggleHelpFul}
                  style={{
                    color: review_helpful === 1 ? 'white' : 'black',
                    backgroundColor: review_helpful === 1 ? 'black' : 'white',
                  }}
                >
                  Helpful
                </Button>
                <BottomPopup
                  title="Report"
                  placeholder="Report"
                  trigger={props => (
                    <Button tiny link noColor {...props}>
                      Report
                    </Button>
                  )}
                  bottomPopupContent={close => {
                    return (
                      <Flex gap={20}>
                        <TextArea
                          placeholder="Describe your issue..."
                          outlined
                          name="report"
                          onChange={e => setReportText(e.target.value)}
                          value={reportText}
                          required
                          noMargin
                        />
                        <Button
                          fluid
                          loading={loading}
                          disabled={!reportText.length}
                          onClick={() => report(close)}
                        >
                          Send Report
                        </Button>
                      </Flex>
                    );
                  }}
                />
              </Flex>
            )}
            <Button small link primary padding="5px 2px" onClick={toggleShow}>
              View Reply{' '}
              <img
                src={DownIcon}
                alt="donw"
                height="10px"
                width="10px"
                style={{ transform: show ? 'rotate(180deg)' : '' }}
              />
            </Button>
          </Flex>
        )}
        {/* REPLY */}
        <Accordion show={show}>
          {<Comments {...replier} reviewId={reviewId} />}
        </Accordion>
      </Flex>
      <MediaPreview
        open={!!previewImage}
        closeHandler={() => {
          setPreviewImage(null);
        }}
        file={previewImage}
        viewMode
        show={!!previewImage}
      />
    </Card>
  );
}

export default CommentCard;
