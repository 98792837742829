import React from 'react';
import './Navbar.css';
import logo from 'src/asset/img/logo.png';
import { Link } from 'react-scroll';

function Navbar() {
  return (
    <div className="ant-nav-wrapper" id="Navbar">
      <div className="ant-nav-left">
        <div className="ant-nav-logo">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="ant-nav-right">
        <div className="ant-nav-list">
          <ul>
            <Link
              className="menu"
              spy={true}
              to="Navbar"
              smooth={true}
              activeClass="activeClass"
            >
              <li>Home</li>
            </Link>
            <Link className="menu" spy={true} to="About" smooth={true}>
              <li>About App</li>
            </Link>
            <Link className="menu" spy={true} to="Features" smooth={true}>
              <li>Features</li>
            </Link>
            <Link className="menu" spy={true} to="Howtohire" smooth={true}>
              <li>How to use?</li>
            </Link>
            <Link className="menu" spy={true} to="contact" smooth={true}>
              <li>Contact</li>
            </Link>
          </ul>
        </div>
        <a className="button navigate" href="https://play.google.com/store/apps/details?id=com.antoiler.twa&pli=1" target="_blank"> Hire Today!</a>
      </div>
    </div>
  );
}

export default Navbar;
