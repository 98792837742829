import React, { useMemo } from 'react';
import { Flex } from 'src/StyledComponents';
import styled from 'styled-components';
import StarColoured from 'src/asset/images/star.svg';
import Star from 'src/asset/images/star-gray.svg';
import ReviewIcon from 'src/asset/images/default-icon.svg';

const Label = styled.p`
  /* font-size: 10px; */
`;

function StarRateView({
  rating = 0,
  large = false,
  onChange = null,
  label,
  name,
  decimal,
}) {
  const ceiledRate = useMemo(() => Math.floor(rating), [rating]);
  const size = useMemo(() => (large ? 15 : 10), [large]);

  return (
    <Flex row justifyBetween>
      {label && (
        <Flex row gap={8}>
          <img
            src={ReviewIcon}
            alt="feed-back"
            height={size + 'px'}
            width={size + 'px'}
          />
          <Label>{label}</Label>
        </Flex>
      )}
      <Flex row gap={large ? 10 : 2} width="max-content">
        {Array(5)
          .fill('')
          .map((_, index) => (
            <img
              key={`star-${index}`}
              alt="star"
              src={index < ceiledRate ? StarColoured : Star}
              height={size + 'px'}
              width={size + 'px'}
              onClick={() => (onChange ? onChange(name, index + 1) : {})}
            />
          ))}
        {onChange && (
          <span style={{ minWidth: decimal ? '25px' : '10px' }}>
            {rating}
            {decimal ? '.0' : ''}
          </span>
        )}
      </Flex>
    </Flex>
  );
}

export default StarRateView;
