import React from 'react';
import { baseURL } from 'src/helpers/api';
import { Flex } from 'src/StyledComponents';
import { Modal, RenderSmoothImage, Button } from '..';
import DeleteIcon from 'src/asset/images/delete.svg';

const MediaPreview = ({
  open,
  file,
  viewMode,
  deleteMedia,
  closeHandler,
  deleteLoading,
}) => {
  return (
    <Modal modalHeight="90%" show={open} onClose={closeHandler}>
      {open && (
        <Flex justifyAround gap={10}>
          <div
            style={{
              width: '100%',
              height: !viewMode ? 'calc(100% - 50px)' : '100%',
            }}
          >
            {file?.type === 'img' ? (
              <RenderSmoothImage
                src={
                  file?.fullSrc ??
                  `${baseURL}/${
                    file?.src ?? file?.thumb_img ?? file?.normal_img
                  }`
                }
                alt={file?.media_id}
                data-media-id={file?.media_id}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <video
                onMouseOver={e => e.target.play()}
                onMouseOut={e => {
                  e.target.pause();
                  e.target.currentTime = 0;
                }}
                controls
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              >
                <source
                  src={file?.fullSrc ?? `${baseURL}/${file?.normal_img}`}
                />
              </video>
            )}
          </div>
          {!viewMode && (
            <Button
              icon={DeleteIcon}
              loading={deleteLoading}
              small
              fluid
              onClick={deleteMedia}
            >
              Remove
            </Button>
          )}
        </Flex>
      )}
    </Modal>
  );
};

export default MediaPreview;
