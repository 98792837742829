import React, { forwardRef } from 'react';
import { Colors } from 'src/constants';
import styled from 'styled-components';

const ActionButton = styled.button`
  position: fixed;
  right: ${props => props?.right ?? 20}px;
  bottom: ${props => props?.bottom ?? 60}px;
  border-radius: 50%;
  color: white;
  border: none;
  min-height: 50px;
  min-width: 50px;
  /* padding: 10px; */
  font-size: 1.5em;
  background: ${Colors.PRIMARY_ORANGE};
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(219, 216, 219, 1);
  -moz-box-shadow: 5px 5px 10px 0px rgba(219, 216, 219, 1);
  box-shadow: 5px 5px 10px 0px rgba(219, 216, 219, 1);
`;

const Fab = forwardRef(({ children, right, bottom, ...rest }, ref) => {
  return (
    <ActionButton ref={ref} right={right ?? 20} bottom={bottom ?? 60} {...rest}>
      {children}
    </ActionButton>
  );
});

export default Fab;
