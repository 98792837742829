import React, { useState } from 'react';
import { Button } from 'src/components';
import { Card, Flex, HideTextOverflow } from 'src/StyledComponents';
import styled from 'styled-components';
import { Colors } from 'src/constants';
import { numberShortener } from 'src/helpers/util';

const Rate = styled.h3`
  font-size: 25px;
  color: ${Colors.PRIMARY_ORANGE};
`;

const RatePer = styled.span`
  font-size: 12px;
  text-transform: capitalize;
`;

const Description = styled.p`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
`;

const RateCard = ({
  width,
  data,
  readOnly = false,
  editHandler = () => {},
  deleteHandler = () => {},
}) => {
  const [expand, setExpand] = useState(false);
  const [loading, setLoader] = useState(false);

  const toggleLoader = () => {
    setLoader(prev => !prev);
  };

  const handleDelete = () => {
    toggleLoader();
    deleteHandler().finally(toggleLoader);
  };

  const toggleExpand = () => {
    setExpand(prev => !prev);
  };

  return (
    <>
      {!expand ? (
        <Card width={width}>
          <Flex row justifyBetween>
            <Flex gap={5} width="max-content" maxWidth="calc(100% - 75px)">
              <HideTextOverflow
                width="100%"
                fontWeight={600}
                style={{ height: '100%' }}
              >
                {data?.title ?? '-'}
              </HideTextOverflow>
              <Flex row gap={10}>
                <Button tiny link onClick={toggleExpand}>
                  {expand ? 'View Less' : 'View More'}
                </Button>
                {!readOnly && (
                  <>
                    <Button tiny link onClick={editHandler}>
                      Edit
                    </Button>
                    <Button tiny link onClick={handleDelete} loading={loading}>
                      Delete
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
            <Flex height="max-content" width="max-content">
              <Rate>₹{data?.rate ? numberShortener(data.rate) : '-'}</Rate>
              <RatePer>{data?.type ?? '-'}</RatePer>
            </Flex>
          </Flex>
        </Card>
      ) : (
        <Card width={width}>
          <Flex gap={10}>
            <Flex row justifyBetween flexStart>
              <Flex gap={5} width="max-content" maxWidth="calc(100% - 75px)">
                <HideTextOverflow width="100%" fontWeight={600}>
                  {data?.title ?? '-'}
                </HideTextOverflow>
              </Flex>
              <Flex height="max-content" width="max-content">
                <Rate>₹{data?.rate ?? '-'}</Rate>
                <RatePer>{data?.type ?? '-'}</RatePer>
              </Flex>
            </Flex>
            <Description>{data?.description ?? '-'}</Description>
            <Flex row justifyBetween>
              <Button tiny link onClick={toggleExpand}>
                {expand ? 'Less' : 'View More'}
              </Button>
              {!readOnly && (
                <Flex row gap={10} width="max-content">
                  <Button
                    tiny
                    link
                    onClick={editHandler}
                    loading={loading.edit}
                  >
                    Edit
                  </Button>
                  <Button tiny link onClick={handleDelete} loading={loading}>
                    Delete
                  </Button>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Card>
      )}
    </>
  );
};

export default RateCard;
