import React, { useState } from 'react';
import { reportUser } from 'src/services';
import { Flex } from 'src/StyledComponents';
import { Button, Modal, TextArea } from '..';

const ReportUser = ({ name, open, userId, close }) => {
  const [reportText, setReportText] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [reporting, setReporting] = useState(false);

  const reportAntoiler = () => {
    const payload = {
      reported_user_id: userId,
      message: reportText,
    };
    setReporting(true);
    reportUser(payload)
      .then(() => {
        setReportText('');
      })
      .finally(() => {
        setReporting(false);
        close();
      });
  };
  return (
    <Modal
      zIndex="10"
      show={open}
      title={`Report ${name}`}
      onClose={e => {
        e.stopPropagation();
        close();
        setReportText('');
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Flex gap={20}>
        <TextArea
          placeholder="Describe your issue..."
          outlined
          name="report"
          onChange={e => setReportText(e.target.value)}
          value={reportText}
          required
          noMargin
        />
        <Button
          fluid
          loading={reporting}
          disabled={!reportText.length}
          onClick={() => reportAntoiler()}
        >
          Send Report
        </Button>
      </Flex>
    </Modal>
  );
};

export default ReportUser;
