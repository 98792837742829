/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import CardCarousel from '../card-carousel/Card_Carousel';

import './Banner.css';

function Banner() {
  const [proff, setproff] = useState('Software Eng.');
  const [PList,setPList] = useState(['Carpenter', 'Plumber', 'Electrician']);

  function changeProf(newProf) {
    setproff(newProf);
  }

  return (
    <div className="ant-banner">
      <div className="ant-banner-heading">

      {/* <div class="content">
  <div class="slider-content__container">
    <p class="slider-content__container__text">
      Hello
    </p>
    
    <ul class="slider-content__container__list">
      {PList.map((singleList)=>(
        <li class="slider-content__container__list__item">{singleList}</li>
      ))}


    </ul>
  </div>
</div> */}

        <h1 className="ant-heading">
          Are you looking for a Nearby &nbsp; <span className='slider-text'> {proff}!</span>
        </h1>

        <p className="ant-subheading">
          Download the App and find Nearby Verified Professionals aka Antoilers
          at your Fingertips
        </p>
      </div>
      <div>
        <CardCarousel changeProf={changeProf} PList={PList} />
      </div>

      <div className="ant-download">

        <a className="button" href="https://play.google.com/store/apps/details?id=com.antoiler.twa&pli=1" target={'_blank'} >Download App</a>
        <p className="ant-or">OR</p>
        <p className="ant-link">
          <a href="#">www.antoiler.com</a> On Your Mobile
        </p>
      </div>
    </div>
  );
}

export default Banner;
