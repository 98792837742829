import Axios from 'axios';
import { getToken } from './storage';
import { toast } from 'react-toastify';
import { toastOptions } from 'src/constants';
export const CancelToken = Axios.CancelToken;

const pending = {};

const removePending = (config, f) => {
  // make sure the url is same for both request and response
  const url = config.url.replace(config.baseURL, '/');
  // stringify whole RESTful request with URL params
  const flagUrl =
    url + '&' + config.method + '&' + JSON.stringify(config.params);
  if (flagUrl in pending) {
    if (f) {
      f(); // abort the request
    } else {
      delete pending[flagUrl];
    }
  } else {
    if (f) {
      pending[flagUrl] = f; // store the cancel function
    }
  }
};

export const baseURL = `https://api.antoiler.com/`;
// export const baseURL = `http://buildmyresume.tk/`;
// export const baseURL = `https://tcdesigners.online/`;

export const apiURL = baseURL + 'api';

export const contentType = {
  JSON: 'application/json',
  FORM_DATA:
    'multipart/form-data; boundary=----WebKitFormBoundaryvfgv8ebXxoPSHYCX',
};

const headers = {
  'Content-Type': contentType.JSON,
};

const uploadApiHeaders = {
  'Content-Type': contentType.FORM_DATA,
};

const AXIOS_CONFIG = type => ({
  baseURL: apiURL,
  headers: type === 'json' ? headers : uploadApiHeaders,
});

export const API = Axios.create(AXIOS_CONFIG('json'));
export const UPLOAD_API = Axios.create(AXIOS_CONFIG('upload'));

UPLOAD_API.interceptors.request.use(config => ({
  ...config,
  headers: {
    ...config.headers,
    auth: getToken(),
  },
}));

API.interceptors.request.use(config => {
  return {
    ...config,
    headers: {
      ...config.headers,
      auth: getToken(),
    },
    cancelToken:
      config.cancelToken ||
      new CancelToken(c => {
        removePending(config, c);
      }),
  };
});

API.interceptors.response.use(
  function (response) {
    const {
      data: { res, msg, err },
    } = response;

    removePending(response.config);

    const message = err ?? msg;
    if (res !== undefined && !res) {
      toast.error(message, toastOptions);
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

UPLOAD_API.interceptors.response.use(
  function (response) {
    const {
      data: { res, msg, err },
    } = response;

    const message = err ?? msg;
    if (!res) {
      toast.error(message, toastOptions);
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
