import React from 'react';
import { Colors } from 'src/constants';
import { Flex } from 'src/StyledComponents';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${Colors.PRIMARY_ORANGE};
  stroke-width: 4px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);

  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: grid;
  place-items: center;
  width: 16px;
  height: 16px;
  /* background: ${props => (props.checked ? 'salmon' : 'papayawhip')}; */
  border: 0.5px solid
    ${props => (props.checked ? Colors.PRIMARY_ORANGE : Colors.DARK)};
  border-radius: 3px;
  transition: all 150ms;

  /* ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  } */

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Text = styled.span`
  font-size: 10px;
`;

const Checkbox = ({ className, checked, label, ...props }) => (
  <label>
    <Flex row gap={8}>
      <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox checked={checked}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      <Text>{label}</Text>
    </Flex>
  </label>
);

export default Checkbox;
