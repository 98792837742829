import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconImg from 'src/asset/img/logo.png';
import './Form.css';
import { SaveBecomeAntoiler } from 'src/services';

export default function Form({ popupisopen, togglepopup }) {
  // const submitHandler = () => {
  //   togglepopup();
  // };
  const [FormErr, setFormErr] = React.useState({
    name: { err: false, helpTxt: '',is_req:true },
    email: { err: false, helpTxt: '',is_req:false },
    phone: { err: false, helpTxt: '',is_req:true },
    profession: { err: false, helpTxt: '',is_req:true },
    address: { err: false, helpTxt: '',is_req:true },
  });
  const [response, setResponse] = React.useState({ cls: '', msg: '' });

  const HandleSubmit = async e => {
    e.preventDefault();
    setFormErr({
      name: { err: false, helpTxt: '',is_req:true },
      email: { err: false, helpTxt: '',is_req:false },
      phone: { err: false, helpTxt: '',is_req:true },
      profession: { err: false, helpTxt: '',is_req:true },
      address: { err: false, helpTxt: '',is_req:true },
    });

    let name = e.target.name.value;
    let email = e.target.email.value;
    let phone = e.target.phone.value;
    let address = e.target.address.value;
    let profession = e.target.profession.value;

    let isFormValid = 1;
    if (!name) {
      setFormErr(oldVal => ({
        ...oldVal,
        name: { err: true, helpTxt: 'Please Fill Your Name' },
      }));
      isFormValid = 0;
    }

    // if (!email) {
    //   setFormErr(oldVal => ({
    //     ...oldVal,
    //     email: { err: true, helpTxt: 'Please Fill Your Email' },
    //   }));
    //   isFormValid = 0;
    // }

    if (!phone || phone.length !== 10) {
      setFormErr(oldVal => ({
        ...oldVal,
        phone: { err: true, helpTxt: 'Please Fill Your Valid Mobile Number' },
      }));
      isFormValid = 0;
    }

    if (!address) {
      setFormErr(oldVal => ({
        ...oldVal,
        address: { err: true, helpTxt: 'Please Fill Your Address' },
      }));
      isFormValid = 0;
    }

    if (!profession) {
      setFormErr(oldVal => ({
        ...oldVal,
        profession: { err: true, helpTxt: 'Please Fill Your Profession' },
      }));
      isFormValid = 0;
    }

    if (isFormValid) {
      let SendObj = {
        name: name,
        email: email,
        phone: phone,
        address: address,
        profession: profession,
      };
      let res = await SaveBecomeAntoiler(SendObj);
      // console.log(res);
      if (res.statusText === 'OK') {
        if (res.data.status) {
          setResponse({ cls: 'alert alert-success', msg: res.data.msg });
          e.target.reset();
          HideMesg();
          return;
        }
      }
      setResponse({
        cls: 'alert alert-danger',
        msg: 'Something went Wrong Please Try Again',
      });
      HideMesg();
    }
  };

  const HideMesg = () => {
    setTimeout(() => {
      setResponse({ cls: '', msg: '' });
    }, 4000);
  };

  const handlerChange = e => {
    let targetName = e.target.name;
    let targetVal = e.target.value;
    
    if(targetName==='email') return;

    if (targetVal) {
      setFormErr(oldVal => ({
        ...oldVal,
        [targetName]: { err: false, helpTxt: `` },
      }));
    } else {
      setFormErr(oldVal => ({
        ...oldVal,
        [targetName]: { err: true, helpTxt: `Please Fill Your ${targetName}` },
      }));
    }
  };

  return (
    <div>
      <Dialog
        open={popupisopen}
        onClose={togglepopup}
        sx={{}}
        id="bcome-antoiler-modal"
      >
        <div className="modal-section">
          <div className="modadl-header">
            <img src={IconImg} style={{ width: '145px' }} alt="logo" />
            <DialogTitle className="modal-title">
              Become an Antoiler
            </DialogTitle>
            <small>To Become Antoiler please fill this form.</small>
          </div>

          {/* Profession And Location */}

          <DialogContent>
            <form
              className="become-an-antoiler"
              id="become-an-antoiler-from"
              onSubmit={HandleSubmit}
              autoComplete="off"
            >
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Your name"
                type="text"
                fullWidth
                variant="standard"
                autoComplete="off"
                name="name"
                error={FormErr.name.err}
                onBlur={handlerChange}
                helperText={FormErr.name.helpTxt}
              />
              <TextField
                margin="dense"
                id="email"
                label="Your email"
                type="email"
                fullWidth
                variant="standard"
                autoComplete="off"
                name="email"
                error={FormErr.email.err}
                onBlur={handlerChange}
                helperText={FormErr.email.helpTxt}
              />
              <TextField
                margin="dense"
                id="phone"
                label="Your phone no"
                type="number"
                fullWidth
                name="phone"
                variant="standard"
                autoComplete="off"
                error={FormErr.phone.err}
                onBlur={handlerChange}
                helperText={FormErr.phone.helpTxt}
              />

              <TextField
                margin="dense"
                id="profession"
                label="Your Profession"
                type="text"
                fullWidth
                variant="standard"
                autoComplete="off"
                name="profession"
                error={FormErr.profession.err}
                onBlur={handlerChange}
                helperText={FormErr.profession.helpTxt}
              />

              <TextField
                margin="dense"
                id="address"
                label="Adddress"
                type="textarea"
                fullWidth
                variant="standard"
                name="address"
                autoComplete="off"
                error={FormErr.address.err}
                onBlur={handlerChange}
                helperText={FormErr.address.helpTxt}
              />
            </form>
          </DialogContent>
          <DialogActions sx={{ display: 'block' }}>
            <div className={response.cls + ` form-response`}>
              {response.msg}
            </div>
            <div className="modal-footer">
              <Button onClick={togglepopup}>Cancel</Button>
              <button className="submit" form={'become-an-antoiler-from'}>
                Submit
              </button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
