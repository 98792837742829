import React from 'react';
import styled from 'styled-components';
import { Colors } from 'src/constants';

export const Progress = styled.div`
  width: 100%;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
`;

export const ProgressLevel = styled.div`
  width: ${props => props.width || '0%'};
  background-color: ${Colors.PRIMARY_ORANGE};
  height: 5px;
`;

const ProgressBar = ({ value }) => {
  return (
    <Progress>
      <ProgressLevel width={`${value}%`} />
    </Progress>
  );
};

export default ProgressBar;
