import styled, { keyframes } from 'styled-components';
import { Colors } from './constants';

const getJustify = props => {
  if (props.justifyBetween) return 'space-between';
  if (props.centered) return 'center';
  if (props.justifyAround) return 'space-around';
  if (props.justifyEnd) return 'end';
  if (props.leftAlign) return 'left';
  return 'unset';
};

const getAlignItems = props => {
  if (props.flexStart) return 'flex-start';
  if (props.flexEnd) return 'flex-end';
  if (props.alignBaseline) return 'baseline';
  return 'center';
};

export const Flex = styled.div`
  display: flex;
  ${props => (props.margin ? `margin: ${props.margin};` : '')}
  ${props => (props.wrap ? 'flex-wrap: wrap;' : '')}
  ${props => (props.padding ? `padding: ${props.padding};` : '')}
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
  ${props => (props.maxHeight ? `max-height: ${props.maxHeight};` : '')}
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  justify-content: ${props => getJustify(props)};
  align-items: ${props => getAlignItems(props)};
  gap: ${props => props?.gap || '0'}px;
  height: ${props => props.height || '100%'};
  width: ${props => props.width || '100%'};
`;

export const H2 = styled.h2`
  font-size: 20px;
  width: 100%;
`;

export const Card = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: ${props =>
    props.lightShadow ? '0px 3px 6px #0000000a' : '0px 3px 6px #00000029'};
  border-radius: 10px;
  padding: ${props => (props.noPadding ? '0px' : '1rem')};
  width: ${props => (props.width ? props.width : '100%')};
  ${props => (props.height ? `height: ${props.height};` : '')}
`;

export const Section = styled.h1`
  text-align: left;
  font: normal normal 600 19px Montserrat;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 20px 0;
  &:after {
    content: '';
    width: 100%;
    border-bottom: 1px solid ${Colors.DARK};
  }
`;

export const HideTextOverflow = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${props => props.fontSize ?? ''};
  width: ${props => props.width ?? 'auto'};
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
  ${props => (props.fontWeight ? `font-weight: ${props.fontWeight};` : '')}
  ${props => (props.alignCenter ? 'text-align: center;' : '')}
  ${props => props?.noScroll && `overflow: hidden;`}
`;

export const LeftAlignP = styled.p`
  text-align: left;
  margin: 5px;
`;

export const ContentWrapper = styled.div`
  ${props => !props.noPadding && `padding: ${props.padding || '4rem 1.5rem'};`}
  width: 100%;
  height: ${props => props.height || '100%'};
  display: flex;
  flex-direction: column;
  gap: ${props => (props?.gap ? props?.gap + 'px' : 0)};
  ${props => props?.scrollable && `overflow: scroll;`}
`;

export const Image = styled.img`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
`;

export const Accordion = styled.div`
  width: 100%;
  overflow: hidden;
  max-height: ${props => (props.show ? 100 : 0)}vh;
  transition: max-height 1s ease-in-out;
`;

export const HR = styled.hr`
  background-color: ${Colors.HR_BG_COLOR};
  height: 0px;
  border-top: ${props => props.height ?? 1}px;
  width: ${props => props.width ?? '100%'};
`;

export const NotificationDot = styled.div`
  width: 2px;
  height: 2px;
  background-color: red;
  border-radius: 50%;
`;

export const SectionWithHeading = styled.p`
  display: flex;
  white-space: nowrap;
  width: 100%;
  font-weight: 600;
  gap: 10px;
  align-items: center;
  margin-bottom: ${props => (props.noMargin ? 0 : 10)}px;

  &:after {
    content: '';
    height: 1px;
    width: 100%;
    background: #c5c5c5;
  }
`;

const getTextColor = props => {
  if (props?.outline || props.secondary) return Colors.BLACK;
  if (props.link) {
    if (props.noColor) return Colors.BACKGROUND_GRAY;
    if (props.primary) return Colors.PRIMARY_ORANGE;
    if (props.neon) return Colors.NEON_BLUE;
    return Colors.LINK_BLUE;
  }
  return Colors.WHITE;
};

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: ${props => getTextColor(props)};
  opacity: ${({ loading }) => (loading ? 1 : 0)};
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  width: 16px;
  height: 16px;
  transition: opacity 200ms;
  animation: ${rotate} 1s linear;
  animation-iteration-count: infinite;
  transition-delay: ${({ loading }) => (loading ? '200ms' : '0ms')};
`;
