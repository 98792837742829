import firebase from 'firebase/compat/app';
import { signOut } from 'firebase/auth';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBL99logVTYTqjlRPtV2eXsE0DwLvtqhYU',
  authDomain: 'toiler-project.firebaseapp.com',
  projectId: 'toiler-project',
  storageBucket: 'toiler-project.appspot.com',
  messagingSenderId: '71138331331',
  appId: '1:71138331331:web:36c819a82220fc89b5f076',
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();

const logOut = () => signOut(auth);

export { auth, firebase, logOut };
