import { useCallback, useContext, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { baseURL } from 'src/helpers/api';
import { getDialedUser, removeDialedUser } from 'src/helpers/storage';
import { StoreContent } from 'src/helpers/store';
import { toilerContacted, toilerHired } from 'src/services';
import { Flex } from 'src/StyledComponents';
import styled from 'styled-components';
import { Button, RenderSmoothImage } from '..';
import { AvatarWrapper } from '../ProfileCard/StyledComponents';

const DialedUserHandlerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const Popup = styled.div`
  width: 90vw;
  max-width: 300px;
  height: max-content;
  background-color: white;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
`;

const CheckDialedUser = () => {
  const [dialedUser, setDialedUser] = useState(null);

  const {
    user: [user],
  } = useContext(StoreContent);

  // const navigate = useNavigate();

  const checkStorageForDialedUser = useCallback(() => {
    const dialedUser = getDialedUser();
    setDialedUser(dialedUser);
  }, []);

  useEffect(() => {
    checkStorageForDialedUser();
    window.addEventListener('focus', checkStorageForDialedUser);
    return () => {
      window.addEventListener('focus', checkStorageForDialedUser);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dialedUserHandled = () => {
    setDialedUser(null);
    removeDialedUser();
  };

  const handleClick = action => {
    if (action === 'yes') {
      toilerHired(user.user_id, dialedUser.user_id);
      // navigate(`/review/submit-feedback/${dialedUser.user_id}`);
    } else if (action === 'no') {
      toilerContacted(dialedUser.user_id);
    }
    dialedUserHandled();
  };

  return !!dialedUser ? (
    <DialedUserHandlerContainer>
      <Popup>
        <AvatarWrapper circle size="100px">
          {dialedUser?.profile_pic ? (
            <RenderSmoothImage
              src={baseURL + dialedUser?.profile_pic}
              objectFit="cover"
            />
          ) : null}
        </AvatarWrapper>
        <p>
          Did you hire <b>{dialedUser.first_name}</b>?
        </p>
        <Flex row gap={10} centered>
          <Button small onClick={() => handleClick('yes')}>
            Yes
          </Button>
          <Button outline small onClick={() => handleClick('no')}>
            No
          </Button>
        </Flex>
        <Button small link padding="5px 10px" onClick={() => handleClick()}>
          Didn't Contact
        </Button>
      </Popup>
    </DialedUserHandlerContainer>
  ) : null;
};

export default CheckDialedUser;
