import React from 'react'
import './Topfeatures.css'
import Scroll from 'react-scroll';

var Element = Scroll.Element;



function Topfeatures({popupisopen,togglepopup}) {
  return (
   <Element name="Features" >
    <div className='ant-top-features element' id='Features' >
       <div className='ant-features-heading'>
           <p className='top-heading'>Top <span>Features!</span></p>
           <p className='top-subheading'>Hire NearBy Professional and Avail These Features.</p>
       </div>
       <div className='ant-features'>
        <div className='ant-feature-column'>
            <p className='shape1' >
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className='shape-svg'>
            <path
               fill="#F15A24"
               d="M37.6,-66.7C50.6,-57.6,64.4,-51.4,74.6,-40.8C84.8,-30.1,91.3,-15.1,91.7,0.2C92.1,15.5,86.4,31,74.6,38.9C62.8,46.7,44.9,46.8,31.5,52.9C18,59.1,9,71.3,-2,74.9C-13.1,78.4,-26.2,73.2,-39.2,66.8C-52.2,60.4,-65.1,52.7,-74.5,41.4C-83.9,30,-89.7,15,-88.6,0.6C-87.5,-13.8,-79.5,-27.5,-70,-38.6C-60.5,-49.7,-49.5,-58.1,-37.5,-67.9C-25.6,-77.6,-12.8,-88.7,-0.3,-88.2C12.3,-87.7,24.5,-75.8,37.6,-66.7Z"
               transform="translate(100 100)"
               />
            </svg>

            <span className='shape-count'>1</span>
            </p> 
           <p className='feature-heading'>Professionals <span>Near You</span></p>
           <p className='feature-description'>
           Your neighbor might be the service provider you are looking for, but you are not aware of that. All the service providers or Antoiler you are looking for will be available near you or within 5 kilometers. Our priority is to make sure that you get the nearest Antoiler.
           </p>
        </div>
        <div className='ant-feature-column'>
        <p className='shape1' >
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className='shape-svg'>
            <path
               fill="#F15A24"
               d="M37.6,-66.7C50.6,-57.6,64.4,-51.4,74.6,-40.8C84.8,-30.1,91.3,-15.1,91.7,0.2C92.1,15.5,86.4,31,74.6,38.9C62.8,46.7,44.9,46.8,31.5,52.9C18,59.1,9,71.3,-2,74.9C-13.1,78.4,-26.2,73.2,-39.2,66.8C-52.2,60.4,-65.1,52.7,-74.5,41.4C-83.9,30,-89.7,15,-88.6,0.6C-87.5,-13.8,-79.5,-27.5,-70,-38.6C-60.5,-49.7,-49.5,-58.1,-37.5,-67.9C-25.6,-77.6,-12.8,-88.7,-0.3,-88.2C12.3,-87.7,24.5,-75.8,37.6,-66.7Z"
               transform="translate(100 100)"
               />
            </svg>

            <span className='shape-count'>2</span>
            </p> 

            <p className='feature-heading'>Users to <span>Antoiler</span></p>
            <p className='feature-description'>
            Users can also <b  className='link' onClick={togglepopup} >Become an Antoiler</b>. if they have unique talent and experience. You can simply click on Become an Antoiler and Fill up the form, after the verification, your profile will be live on the App and you will be listed as an Antoiler where people can Hire You.
           </p>
        </div>
        <div className='ant-feature-column'>
        <p className='shape1' >
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className='shape-svg'>
            <path
               fill="#F15A24"
               d="M37.6,-66.7C50.6,-57.6,64.4,-51.4,74.6,-40.8C84.8,-30.1,91.3,-15.1,91.7,0.2C92.1,15.5,86.4,31,74.6,38.9C62.8,46.7,44.9,46.8,31.5,52.9C18,59.1,9,71.3,-2,74.9C-13.1,78.4,-26.2,73.2,-39.2,66.8C-52.2,60.4,-65.1,52.7,-74.5,41.4C-83.9,30,-89.7,15,-88.6,0.6C-87.5,-13.8,-79.5,-27.5,-70,-38.6C-60.5,-49.7,-49.5,-58.1,-37.5,-67.9C-25.6,-77.6,-12.8,-88.7,-0.3,-88.2C12.3,-87.7,24.5,-75.8,37.6,-66.7Z"
               transform="translate(100 100)"
               />
            </svg>

            <span className='shape-count'>3</span>
            </p> 
            <p className='feature-heading'>Workdone</p>
            <p className='feature-description'>
            Antoilers can showcase they are using this feature. Where they can upload nice photographs of their work. This feature will be more useful for Carpenters, Event Planners, Mehandi Wale, Beauty Parlour, and Painters in Gwalior.
           </p>
        </div>

       </div>
       <div className='ant-features'>
        <div className='ant-feature-column'>
        <p className='shape1' >
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className='shape-svg'>
            <path
               fill="#F15A24"
               d="M37.6,-66.7C50.6,-57.6,64.4,-51.4,74.6,-40.8C84.8,-30.1,91.3,-15.1,91.7,0.2C92.1,15.5,86.4,31,74.6,38.9C62.8,46.7,44.9,46.8,31.5,52.9C18,59.1,9,71.3,-2,74.9C-13.1,78.4,-26.2,73.2,-39.2,66.8C-52.2,60.4,-65.1,52.7,-74.5,41.4C-83.9,30,-89.7,15,-88.6,0.6C-87.5,-13.8,-79.5,-27.5,-70,-38.6C-60.5,-49.7,-49.5,-58.1,-37.5,-67.9C-25.6,-77.6,-12.8,-88.7,-0.3,-88.2C12.3,-87.7,24.5,-75.8,37.6,-66.7Z"
               transform="translate(100 100)"
            />
            </svg>

            <span className='shape-count'>4</span>
            </p> 
           <p className='feature-heading'>Rate <span>Card</span></p>
           <p className='feature-description'>
           This is a really important feature for your customers/users to know how much do you charge as an Antoiler. You can list down the charges of your service. We have covered the type of the basic charge in the app, where you can charge per hour, per service, per day, and per month.
           </p>
        </div>
        <div className='ant-feature-column'>
        <p className='shape1' >
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className='shape-svg'>
            <path
               fill="#F15A24"
               d="M37.6,-66.7C50.6,-57.6,64.4,-51.4,74.6,-40.8C84.8,-30.1,91.3,-15.1,91.7,0.2C92.1,15.5,86.4,31,74.6,38.9C62.8,46.7,44.9,46.8,31.5,52.9C18,59.1,9,71.3,-2,74.9C-13.1,78.4,-26.2,73.2,-39.2,66.8C-52.2,60.4,-65.1,52.7,-74.5,41.4C-83.9,30,-89.7,15,-88.6,0.6C-87.5,-13.8,-79.5,-27.5,-70,-38.6C-60.5,-49.7,-49.5,-58.1,-37.5,-67.9C-25.6,-77.6,-12.8,-88.7,-0.3,-88.2C12.3,-87.7,24.5,-75.8,37.6,-66.7Z"
               transform="translate(100 100)"
               />
            </svg>

            <span className='shape-count'>5</span>
            </p> 

            <p className='feature-heading'>Reviews</p>
            <p className='feature-description'>
            You as a User can Rate  Antoilers (Service Providers) based on their Quality and Work which will ultimately help them to get more work based on your valuable feedback. You as Antoiler can showcase your working capabilities by showing the verified feedback, you've received.
           </p>
        </div>
        <div className='ant-feature-column'>
        <p className='shape1' >
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className='shape-svg'>
            <path
               fill="#F15A24"
               d="M37.6,-66.7C50.6,-57.6,64.4,-51.4,74.6,-40.8C84.8,-30.1,91.3,-15.1,91.7,0.2C92.1,15.5,86.4,31,74.6,38.9C62.8,46.7,44.9,46.8,31.5,52.9C18,59.1,9,71.3,-2,74.9C-13.1,78.4,-26.2,73.2,-39.2,66.8C-52.2,60.4,-65.1,52.7,-74.5,41.4C-83.9,30,-89.7,15,-88.6,0.6C-87.5,-13.8,-79.5,-27.5,-70,-38.6C-60.5,-49.7,-49.5,-58.1,-37.5,-67.9C-25.6,-77.6,-12.8,-88.7,-0.3,-88.2C12.3,-87.7,24.5,-75.8,37.6,-66.7Z"
               transform="translate(100 100)"
               />
            </svg>

            <span className='shape-count'>6</span>
            </p> 
            <p className='feature-heading'>Antoiler <span>Dashboard</span></p>
            <p className='feature-description'>
            We have developed a complete dashboard where Antoilers (Service Providers) can track how many times they've been Shared, Hired, Contacted, and Viewed on a Daily and Monthly Basis. This will make them more aware of their reach at Antoiler.
           </p>
        </div>

       </div>
    </div>
               </Element>
  )
}

export default Topfeatures