const { API } = require('src/helpers/api');

export const getToilerProfile = id => {
  return API.get(`/toiler/${id}`);
};

export const getUserProfile = () => {
  return API.get(`/user-profile`);
};

export const getToilerReviews = (id, params) => {
  return API.get(`/toiler-reviews/${id}`, { params });
};

export const getReviewReplies = id => {
  return API.get('/review-replies');
};

export const getToilerPortfolio = (
  id,
  { payload: { start, type }, cancelToken }
) => {
  return API.get(`/toiler-portfolio/${id}?start=${start}&type=${type}`, {
    cancelToken,
  });
};

export const getToilerRates = (id, params) => {
  return API.get(`/toiler-ratecard/${id}`, { params });
};

export const getContactNumber = id => {
  return API.get(`/get-toiler-contact/${id}`);
};

export const toilerContacted = toilerId => {
  return API.get(`/add-contact/${toilerId}`);
};

export const toilerHired = (userId, toilerId) => {
  return API.get(`/hire-antoiler/${toilerId}`); // userId goes undefined that's why removed and we getting userId from AUTH token
};

export const markAsHelpful = id => {
  return API.get(`/like/${id}`);
};

export const addToFav = id => {
  return API.get(`/add-to-fav/${id}`);
};

export const shareProfile = id => {
  return API.get(`/add_share/${id}`);
};

export const reportUser = payload => {
  return API.post(`/report_user`, payload);
};

export const reviewReply = payload => {
  return API.post('/reply-review', payload);
};

export const toilerViewed = id => {
  return API.get(`/add-views/${id}`);
};

export const getHiredToilers = () => {
  return API.get('/get-hired');
};

export const getFavToilers = () => {
  return API.get('/wishlist');
};

export const getContactedToilers = () => {
  return API.get('/get-contact');
}

export const getPublishedReviews = () => {
  return API.get(`published-reviews`);
};

export const getDeclinedReviews = () => {
  return API.get(`declined-reviews`);
};
