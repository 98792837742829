import React from 'react';
import styled from 'styled-components';
import { Flex } from 'src/StyledComponents';
import { Colors, Fonts } from 'src/constants';

const ProfileMetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => (props.colored || props.xl ? '#F3F3F3' : 'white')};
  padding: ${props => (props.colored || props.xl ? '12px' : '0px')};
  border-radius: 4px;
  ${props =>
    props.colored &&
    `height: 45px;
  width: 45px;`}
  ${props =>
    props.xl &&
    `height: 75px;
  width: 75px;`}
  ${props => (props.width ? `width: ${props.width};` : '')}
  ${props => (props.height ? `height: ${props.height};` : '')}
`;

const MainText = styled.p`
  font-size: ${props => (props.fontSize ? props.fontSize : 14)}px;
  font-family: ${Fonts.POPPINS};
  font-weight: 600;
  ${props => props.colored && `color:${Colors.PRIMARY_ORANGE}`}
`;

const SubText = styled.p`
  font-size: ${props => (props.fontSize ? props.fontSize : 8)}px;
  font-family: ${Fonts.POPPINS};
  font-weight: 400;
  text-align: ${props => (props.xl ? 'left' : 'center')};
  line-height: 1;
  width: 100%;
  ${props => props.sub && `color:${Colors.LIGHT_GREY_A90}; margin-top: 3px;`}
`;

const ProfileMetric = ({
  preIcon,
  postIcon,
  mainText,
  subText,
  hintText,
  width,
  height,
  fontSize,
  subFontSize,
  colored,
  xl = false,
  onClick = () => {},
}) => {
  return (
    <ProfileMetricContainer
      xl={xl}
      colored={colored}
      width={width}
      height={height}
      onClick={onClick}
    >
      <Flex gap={0} row centered={!xl} width={xl ? '100%' : '50px'}>
        {preIcon && <img height="10" width="15" src={preIcon} alt="icon" />}
        {mainText && (
          <MainText colored={colored} fontSize={fontSize}>
            {mainText}
          </MainText>
        )}
        {postIcon && <img height="10" width="15" src={postIcon} alt="icon" />}
      </Flex>

      <SubText xl={xl} fontSize={subFontSize ?? fontSize / 1.6}>
        {subText}
      </SubText>
      {hintText && (
        <SubText
          xl={xl}
          sub
          fontSize={subFontSize ? subFontSize - 1 : fontSize / 2.5}
        >
          {hintText}
        </SubText>
      )}
    </ProfileMetricContainer>
  );
};

export default ProfileMetric;
