import React from 'react';
import { Flex } from 'src/StyledComponents';
import styled from 'styled-components';
import { Colors } from 'src/constants';

const Container = styled.div`
  padding: 8px 10px;
  border: 0.5px solid
    ${props => (props.active ? Colors.PRIMARY_ORANGE : Colors.DARK)};
  color: ${props => (props.active ? Colors.PRIMARY_ORANGE : Colors.DARK)};
  border-radius: 2px;
`;

const CustomSelect = ({
  config = [],
  icon,
  activeIcon,
  value = null,
  onChange = () => {},
}) => {
  const clickHandler = index => {
    onChange(config[index]?.value ?? config[index]);
  };

  return (
    <Flex row justifyBetween>
      {config.map((option, index) => (
        <Container
          key={`custom-select-${index}`}
          active={value === (option.value ?? option)}
          onClick={() => clickHandler(index)}
        >
          <Flex row centered gap={5}>
            <p>{option.label ?? option}</p>
            {icon && (
              <img
                src={value === (option.value ?? option) ? activeIcon : icon}
                alt="star"
                width="12px"
              />
            )}
          </Flex>
        </Container>
      ))}
    </Flex>
  );
};

export default CustomSelect;
