import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ActionDropdown } from 'src/components';
import { StoreContent } from 'src/helpers/store';

import HomeIcon from 'src/asset/images/home.svg';
import HeartIcon from 'src/asset/images/heart.svg';
import UsersIcon from 'src/asset/images/users.svg';
import UserIcon from 'src/asset/images/user.svg';
import NoImagePlaceholder from 'src/asset/images/user-filled.svg';
import ToggleButton from '../ToggleButton';
import { Colors } from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'src/firebase';
import RenderSmoothImage from '../RenderSmoothImage';
import { baseURL } from 'src/helpers/api';
import { getUserType } from 'src/helpers/storage';
import { Flex, NotificationDot } from 'src/StyledComponents';
import { toggleOnlineStatus } from 'src/services';

export const Footer = styled.div`
  height: max-content;
  display: flex;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 10px;
  &:active {
    background-color: ${Colors.SECONDARY_ORANGE};
    border-radius: 5px;
  }
`;

export const NavIcon = styled.img`
  width: 100%;
  height: 20px;
  object-fit: contain;
`;

export const ImageContainer = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${Colors.SECONDARY_ORANGE};
  border-radius: 50%;
  overflow: hidden;
`;

function BottomNavigation() {
  const isSp = getUserType() === 'SP';
  const {
    // eslint-disable-next-line no-unused-vars
    status: [isOnline, setStatus],
  } = useContext(StoreContent);

  const {
    user: { 0: user, 2: logoutUser },
  } = useContext(StoreContent);

  const [firebaseUser, loading] = useAuthState(auth);

  const navigate = useNavigate();

  const toggleStatus = () => {
    toggleOnlineStatus().then(res => setStatus(res.data?.status === 1));
  };

  const toDashboard = () => navigate('/dashboard');

  const toHires = () => navigate('/hired');

  const toWishlist = () => navigate('/wishlist');

  const becomeAnAntoiler = () => navigate('/become-an-antoiler?mode=sp');

  const distroySession = () => {
    let thankPage = localStorage.getItem('last_page');
    if (thankPage) {
      logoutUser();
    }
  };
  useEffect(() => {
    distroySession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const items = [
    ...(!loading && firebaseUser
      ? [
          isSp
            ? {
                icon: UserIcon,
                text: `${user.first_name} ${isOnline ? 'Online' : 'Offline'}!`,
                autoClose: false,
                onClick: toggleStatus,
                render: () => (
                  <Flex row width="max-content">
                    <ToggleButton onChange={toggleStatus} checked={isOnline} />
                  </Flex>
                ),
              }
            : {
                iconRender: (
                  <ImageContainer>
                    <RenderSmoothImage
                      src={
                        user.profile_pic
                          ? baseURL + user.profile_pic
                          : NoImagePlaceholder
                      }
                    />
                  </ImageContainer>
                ),
                text: `Hi, ${user.first_name || 'User'}`,
                onClick: toDashboard,
              },
          isSp && {
            icon: UserIcon,
            text: 'Your Account',
            onClick: toDashboard,
          },
          !isSp && {
            icon: UserIcon,
            text: 'Become an Antoiler',
            onClick: becomeAnAntoiler,
          },
          {
            icon: UserIcon,
            text: 'Your Reviews',
            onClick: () => {
              navigate('/published-feedback');
            },
          },
          {
            icon: UserIcon,
            text: 'Logout',
            onClick: () => logoutUser(),
          },
        ]
      : [
          {
            icon: UserIcon,
            text: 'Login',
            onClick: () => navigate('/login'),
          },
          {
            icon: UserIcon,
            text: 'Sign up',
            onClick: () => navigate('/user/signup'),
          },
        ]),
  ].filter(Boolean);

  return (
    <Footer>
      <NavContainer onClick={() => navigate('/')}>
        <NavIcon src={HomeIcon} alt="home" />
        <p style={{ fontSize: '10px' }}>Home</p>
      </NavContainer>
      <NavContainer onClick={toWishlist}>
        <NavIcon src={HeartIcon} alt="home" />
        <Flex row centered gap={2} style={{ fontSize: '10px' }}>
          Wishlist {user.newWishlist && <NotificationDot />}
        </Flex>
      </NavContainer>
      <NavContainer onClick={toHires}>
        <NavIcon src={UsersIcon} alt="home" />
        <p style={{ fontSize: '10px' }}>Hires</p>
      </NavContainer>
      <ActionDropdown
        id="account-action-dropdown"
        placement="TOP"
        items={items}
        medium
        styleProps={{
          width: '100%',
        }}
        trigger={props => (
          <NavContainer {...props}>
            <NavIcon src={UserIcon} alt="home" />
            <p style={{ fontSize: '10px' }}>Account</p>
          </NavContainer>
        )}
      />
    </Footer>
  );
}

export default BottomNavigation;
