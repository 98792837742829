import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Button, Modal } from '..';
import { Flex, Image } from 'src/StyledComponents';

function Cropper({
  src,
  onCrop = () => {},
  triggerName,
  isModalOpen = false,
  setModalOpen,
  onlyPreview = false,
  onClose,
}) {
  const editor = useRef(null);
  const [scale, setScale] = useState(1.2);

  const toggleModal = () => {
    setModalOpen(prev => !prev);
    onClose && onClose();
  };

  const cropHandler = () => {
    if (editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.

      // const canvas = editor.current.getImage();
      // const base64Image = canvas.toDataURL('image/jpeg', 1);

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = editor.current.getImageScaledToCanvas();

      const base64ImageScaled = canvasScaled.toDataURL('image/jpeg', 1);

      onCrop(base64ImageScaled);
      toggleModal();
    }
  };

  return (
    <>
      {triggerName?.length > 0 && (
        <Button link onClick={toggleModal}>
          {triggerName}
        </Button>
      )}

      <Modal show={isModalOpen} onClose={toggleModal}>
        <Flex gap={10}>
          {onlyPreview ? (
            <div style={{ height: '100%' }}>
              <Image
                src={src}
                alt={'preview'}
                width="100%"
                height="100%"
                style={{ objectFit: 'contain' }}
              />
            </div>
          ) : (
            <>
              <div style={{ padding: '1px' }}>
                <AvatarEditor
                  ref={editor}
                  image={src}
                  width={300}
                  height={400}
                  border={15}
                  color={[0, 0, 0, 0.6]} // RGBA
                  scale={scale}
                  rotate={0}
                />
              </div>

              <input
                type="range"
                min="1"
                max="2"
                step={0.1}
                value={scale}
                onChange={({ target: { value } }) => setScale(value)}
              />
              <Button outline onClick={cropHandler}>
                Crop & Save
              </Button>
            </>
          )}
        </Flex>
      </Modal>
    </>
  );
}

export default Cropper;
