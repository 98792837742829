const TOKEN_KEY = 'token';
const REDIRECTION_PATH_KEY = 'redirectionPath';
const OTP_SENT_TIME = 'otpSentTime';
const USER_KEY = 'user';
const USER_TYPE_KEY = 'userType';
const DIALED_USER_KEY = 'dialedUser';

export const removeUserData = () => {
  const KEYS = [TOKEN_KEY, REDIRECTION_PATH_KEY, USER_TYPE_KEY, 'last_page'];
  KEYS.map(key => localStorage.removeItem(key));
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const setToken = (payload = '') => {
  return localStorage.setItem(TOKEN_KEY, payload);
};

export const getUserType = () => {
  return localStorage.getItem(USER_TYPE_KEY);
};

export const setUserType = (payload = '') => {
  return localStorage.setItem(USER_TYPE_KEY, payload);
};

export const getRedirectionPath = () => {
  return localStorage.getItem(REDIRECTION_PATH_KEY);
};

export const removeRedirectionPath = () => {
  return localStorage.removeItem(REDIRECTION_PATH_KEY);
};

export const setRedirectionPath = (payload = '') => {
  return localStorage.setItem(REDIRECTION_PATH_KEY, payload);
};

export const noteOtpSentTime = () => {
  return localStorage.setItem(OTP_SENT_TIME, Date.now());
};

export const getUserFromStorage = () => {
  const data = localStorage.getItem(USER_KEY);
  return data ? JSON.parse(data) : {};
};

export const setUserToStorage = user => {
  return localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const canSendOtp = () => {
  const prevOtpSent = localStorage.getItem(OTP_SENT_TIME);
  return !prevOtpSent ? true : (Date.now() - prevOtpSent) / 1000 > 15;
};

export const setDialedUser = data => {
  return localStorage.setItem(DIALED_USER_KEY, JSON.stringify(data));
};

export const getDialedUser = () => {
  const data = localStorage.getItem(DIALED_USER_KEY);
  return data ? JSON.parse(data) : null;
};

export const removeDialedUser = () => {
  return localStorage.removeItem(DIALED_USER_KEY);
};
