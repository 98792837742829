import React from 'react';
import { Colors } from 'src/constants';
import styled from 'styled-components';

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 25px;
  height: 10px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: ${Colors.TERTIARY_ORANGE};
    }
    &:focus + .slider {
      box-shadow: 0 0 1px ${Colors.TERTIARY_ORANGE};
    }
    &:checked + .slider:before {
      -webkit-transform: translate(12px, -50%);
      -ms-transform: translate(12px, -50%);
      transform: translate(12px, -50%);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    border-radius: 34px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      border-radius: 50%;
      content: '';
      height: 1rem;
      width: 1rem;
      left: 0;
      /* bottom: 4px; */

      top: 50%;
      transform: translateY(-50%);

      background-color: ${Colors.PRIMARY_ORANGE};
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
`;

function ToggleButton(props) {
  return (
    <Switch>
      <input type="checkbox" {...props} />
      <span className="slider"></span>
    </Switch>
  );
}

export default ToggleButton;
