import React from 'react';
import Slider from 'react-slick';
import { Colors } from 'src/constants';
import styled from 'styled-components';
import RenderSmoothImage from '../RenderSmoothImage';
import { baseURL } from 'src/helpers/api';
import Skeleton from 'react-loading-skeleton';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Container = styled.div`
  overflow: hidden;
  max-width: unset;
  div {
    max-width: unset;
  }
  .slick-list {
    width: calc(100vw - 5px);
    margin: auto;
  }
  .slick-dots {
    bottom: 5px;
    li {
      margin: auto;
      text-align: center;
    }
    div {
      display: grid;
      place-items: center;
    }
    & .slick-active {
      div {
        div {
          background: ${Colors.PRIMARY_ORANGE};
          border: none;
        }
      }
    }
  }
`;

const Dot = styled.div`
  border-radius: 50%;
  border: 1px solid white;
  width: 5px;
  height: 5px;
`;

const SlideItem = styled.div`
  border-radius: 15px;

  height: 180px;
  overflow: hidden;
  color: red;
  position: relative;
`;

const Padding = styled.div`
  padding: 10px;
`;

const Content = styled.div`
  position: absolute;
  bottom: 30px;
  left: 25px;
  /* width: 100%; */
  color: ${Colors.WHITE};
  h1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 200;
  }
`;

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  customPaging: i => (
    <div>
      <Dot />
    </div>
  ),
};
function Carousel(props) {
  const { data = [], onClick, loading = false } = props;
  return (
    <div>
      <Container>
        {loading ? (
          <Padding>
            <SlideItem>
              <Skeleton width={'100%'} height={'100%'} />
            </SlideItem>
          </Padding>
        ) : (
          <Slider {...settings}>
            {data.map((item, index) => {
              const { img, title = '', description = '' } = item;
              const imgUrl = baseURL + img;
              return (
                <Padding key={index}>
                  <SlideItem onClick={() => onClick(item)}>
                    <RenderSmoothImage src={imgUrl} objectFit={'cover'} />
                    <Content>
                      <h1>{title}</h1>
                      <p>{description}</p>
                    </Content>
                  </SlideItem>
                </Padding>
              );
            })}
          </Slider>
        )}
      </Container>
    </div>
  );
}

export default Carousel;
