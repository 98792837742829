import React from 'react';
import './States.css';
import playstore from 'src/asset/img/googlplay.png';
// import verifiedToilers from 'src/asset/img/verifiedtoilers.png';
import verifiedToilers1 from 'src/asset/img/verifiedtoilers3.png';
import categories from 'src/asset/img/categories1.png';

import CountUp,{ useCountUp } from 'react-countup';

import VisibilitySensor from "react-visibility-sensor";


function States() {

  // const countUpRef = React.useRef(null);
  // const { start, pauseResume, reset, update } = useCountUp({
  //   ref: countUpRef,
  //   start: 0,
  //   end: 2000,
  //   delay: 100,
  //   duration: 5,
  //   onReset: () => console.log('Resetted!'),
  //   onUpdate: () => console.log('Updated!'),
  //   onPauseResume: () => console.log('Paused or resumed!'),
  //   onStart: ({ pauseResume }) => console.log(pauseResume),
  //   onEnd: ({ pauseResume }) => console.log(pauseResume),
  // });

  const [handleView,setHandleView] = React.useState(false);
  function onChange (isVisible) {
    // setHandleView(isVisible)

    if(isVisible){
      // start();
    }else{
      // pauseResume();
    }
    console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
  }



  return (
  <VisibilitySensor  onChange={onChange} >
    <div className="states">
      <div className="ant-reg-states">

        
        <span className="ant-count"><CountUp end={2000} duration={10}    />+</span>
        <br />
        <span className="ant-count-text">
          <img src={verifiedToilers1} alt="verified toilers" />
          Registered Toilers
        </span>
      </div>
      <div className="ant-cat-states">
        <span className="ant-count"><CountUp end={100} duration={10}    />+</span>
        <br />
        <span className="ant-count-text">
          <img src={categories} alt="categories" />
          Categories
        </span>
      </div>
      <div className="ant-play-store">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="https://play.google.com/store/apps/details?id=com.antoiler.twa&pli=1" target="_blank">
          <img src={playstore} alt="Google Playstore" />
        </a>
      </div>
    </div>
        </VisibilitySensor>
  );
}

export default States;
