import React, { useState } from 'react';
import { Card } from 'src/StyledComponents';

import styled from 'styled-components';
import { Button } from '..';

const Header = styled.h3`
  width: 100%;
  letter-spacing: 0px;
  position: relative;
  font: normal normal 600 18px Poppins;
  margin-bottom: 10px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 5%;
    border-bottom: 2px solid black;
  }
`;

const Description = styled.p`
  width: 100%;
  font: normal normal 300 12px Poppins;
  color: #636363;
  display: -webkit-box;
  line-clamp: ${props => (props.more ? 'unset' : 3)};
  -webkit-line-clamp: ${props => (props.more ? 'unset' : 3)};
  -webkit-box-orient: vertical;
  max-height: ${props => (props.more ? 100 : 4)}rem;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
  margin-bottom: 10px;
`;

const AboutCard = ({ description = '' }) => {
  // STATES
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(prev => !prev);
  };
  return (
    <Card>
      <Header>About</Header>
      <Description more={show}>{description}</Description>
      <Button link neon onClick={toggleShow}>
        {show ? 'View Less' : 'View More'}
      </Button>
    </Card>
  );
};

export default AboutCard;
