import { API } from 'src/helpers/api';

export const getBannerInfo = () => {
  return API.get('/get-slider-img');
};

// export const getToilersByCategory = ({ params = {} }) => {
//   const { categoryId, ...restParams } = params;
//   console.log({ restParams });
//   return API.get(`/toilers/${categoryId}`, { params: restParams });
// };

export const getToilersByCategory = ({ params = {} }) => {
  const {
    categoryId = 0,
    lat = 26.2124007,
    long = 78.1772053,
    start = 0,
  } = params;
  return API.get(`/toilers/${categoryId}/${lat}/${long}/${start}`);
};

export const getSearchResults = ({ params = {} }) => {
  return API.get(`/search`, { params });
};

export const getCategories = () => {
  return API.get(`/fetured-categories`);
};
