import React, { useEffect, useState } from 'react';
import { Colors, SORTING_TYPE } from 'src/constants';
import { Flex, SectionWithHeading } from 'src/StyledComponents';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  BottomPopup,
  Button,
  Checkbox,
  CustomSelect,
  RadioGroup,
  RangeInput,
} from '..';
import BlackStar from 'src/asset/images/black-star.svg';
import ColouredStar from 'src/asset/images/star.svg';
import ProfessionSelect from 'src/pages/SignUp/Sp/UserDetails/ProfessionSelect';
import rupeeIcon from 'src/asset/images/rupees.svg';
import Select from 'react-select';
import CloseSm from 'src/asset/images/CloseSm.png';

const Container = styled.div`
  width: 100%;
  padding: ${props => props.padding ?? '10px 15px'};
  background: ${Colors.WHITE};
`;

const Text = styled.p`
  font-size: 10px;
  span {
    font-size: inherit;
    color: ${Colors.PRIMARY_ORANGE};
  }
`;

const ContentContainer = styled.div`
  width: 100%;
`;

const PricingRange = styled.p`
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  width: max-content;
`;

const Heading = styled.h1`
  width: 100%;
  font-weigth: bold;
  font-size: 18px;
`;

const TagContainer = styled.div`
  width: 100%;
  overflow: auto;
  margin: 5px 0px;
`;

const Tag = styled.p`
  border: 0.6px solid #2e2e2e;
  border-radius: 4px;
  white-space: nowrap;
  padding: 8px 10px;
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const customStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: 'transparent',
    width: '100%',
    outline: 'none',
    boxShadow: 'none',
    border: 'none',
  }),
  placeholder: base => ({
    ...base,
    minWidth: '80px',
  }),
};

const Filter = ({
  resultsCount,
  priceRange,
  updateFilterFromUrl,
  resetToilers,
}) => {
  const checkBoxConfig = [
    {
      label: 'Per Hour',
      value: 'Per Hour',
      name: 'perHour',
    },
    {
      label: 'Per Month',
      value: 'Per Month',
      name: 'perMonth',
    },
    {
      label: 'Per Day',
      value: 'Per Day',
      name: 'perDay',
    },
    {
      label: 'Per Service',
      value: 'Per Service',
      name: 'perService',
    },
  ];

  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();

  const defaultValue = Object.fromEntries([...search]);

  const appliedPriceRange = defaultValue?.['price_range'];

  let defaultValueForRange = { ...priceRange };

  if (appliedPriceRange) {
    defaultValueForRange = {
      min: Number(appliedPriceRange.split('-')[0]),
      max: Number(appliedPriceRange.split('-')[1]),
    };
  }

  const [rangeValue, setRange] = useState(priceRange);

  const [filter, setFilter] = useState(defaultValue);

  const pairKeys = {
    category: '_category',
    _category: 'category',
  };
  const removeFilter = key => {
    const tmpFilter = { ...filter };
    delete tmpFilter?.[key];
    // delete tmpFilter?.['price_range'];
    if (key in pairKeys) {
      delete tmpFilter?.[pairKeys[key]];
    }
    setFilter(tmpFilter);
    updateSearch(tmpFilter);
  };

  const onSubmit = cb => {
    updateSearch();
    cb();
  };

  const updateSearch = filterProps => {
    resetToilers();
    const tmpFilter = filterProps ? filterProps : { ...filter };
    if (!tmpFilter?.['price_type']) delete tmpFilter?.price_type;
    if (
      rangeValue.min &&
      rangeValue.max &&
      !(
        rangeValue.min === defaultValueForRange.min &&
        rangeValue.max === defaultValueForRange.max
      )
    ) {
      tmpFilter['price_range'] = rangeValue.min + '-' + rangeValue.max;
    } else {
      delete tmpFilter['price_range'];
    }
    if (tmpFilter['category']?.value) {
      tmpFilter['_category'] = tmpFilter['category']?.label;
      tmpFilter['category'] = tmpFilter['category']?.value;
    }

    setFilter(tmpFilter);
    setSearch(tmpFilter);
    navigate(
      `/search/${
        search.get('q')?.split(' ').join('-') || ''
      }?${createSearchParams(tmpFilter).toString()}`
    );
  };

  const clearFilter = cb => {
    setSearch({});
    setFilter({});
    cb();
  };

  useEffect(() => {
    setRange(p => ({
      ...p,
      min: priceRange.min && p.min < priceRange.min ? priceRange.min : p.min,
      max: priceRange.max && p.max > priceRange.max ? priceRange.max : p.max,
    }));
    setRange(p => {
      if (p.max < p.min) {
        if (p.max < priceRange.max) return { ...p, max: priceRange.max };
        else if (p.min > priceRange.min) return { ...p, min: priceRange.min };
      } else return p;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRange]);

  useEffect(() => {
    setFilter(() => Object.fromEntries([...search]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFilterFromUrl]);

  const removeUnwantedKeys = ([key, value]) => {
    const unWantedKey = ['category', 'q', 'from_carousel', 'order_by'];

    return !unWantedKey.includes(key);
  };

  return (
    <Container padding="0px 15px">
      <Flex row height="max-content" justifyBetween>
        <Text>
          {search.get('from_carousel') === '1' && filter?._category ? (
            <p style={{ fontSize: '12px' }}>
              All Service Providers from <span>{filter?._category}</span>
            </p>
          ) : (
            <p>{resultsCount} Results Found</p>
          )}
        </Text>
        <div>
          <BottomPopup
            title="Filters"
            placeholder="Filters"
            closeStyle="icon"
            popupHeight="90vh"
            trigger={props => (
              <Button small {...props}>
                FILTER
              </Button>
            )}
            bottomPopupContent={close => {
              return (
                <Flex gap={20} height="100%" style={{ overflow: 'scroll' }}>
                  <Flex row justifyBetween>
                    <Heading>Sorting</Heading>

                    <Select
                      styles={customStyle}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      isSearchable={false}
                      options={SORTING_TYPE}
                      onChange={value =>
                        setFilter(p => ({
                          ...p,
                          order_by: value.value,
                        }))
                      }
                      value={
                        SORTING_TYPE.find(
                          op => op.value === filter?.order_by
                        ) || SORTING_TYPE.find(op => op.value === 'ASC')
                      }
                      placeholder={'Sorting'}
                    />
                  </Flex>

                  <ContentContainer>
                    <SectionWithHeading>Category</SectionWithHeading>
                    <div>
                      <ProfessionSelect
                        noTitle={true}
                        placeholder={'Specify Your Category'}
                        onChange={value => {
                          setFilter(p => ({
                            ...p,
                            category: value,
                          }));
                        }}
                        value={
                          filter?.['category']?.value
                            ? filter?.['category']
                            : filter?._category
                            ? {
                                label: filter?._category,
                                value: filter?.category,
                              }
                            : []
                        }
                      />
                    </div>
                  </ContentContainer>

                  <ContentContainer>
                    <div style={{ marginBottom: '15px' }}>
                      <Flex row gap={10}>
                        <SectionWithHeading noMargin>
                          Pricing
                        </SectionWithHeading>
                        <PricingRange>
                          <img src={rupeeIcon} width="8px" alt="" srcset="" />
                          {rangeValue.min} - {rangeValue.max}
                        </PricingRange>
                      </Flex>
                    </div>
                    <Flex row justifyBetween height="max-content">
                      <RangeInput
                        min={priceRange.min || 0}
                        max={priceRange.max || 100}
                        onChange={({ min, max }) => {
                          setRange({ min, max });
                        }}
                        value={rangeValue}
                      />
                    </Flex>
                  </ContentContainer>

                  <ContentContainer>
                    <SectionWithHeading>Pricing Type</SectionWithHeading>
                    <Flex row justifyBetween height="max-content">
                      {checkBoxConfig.map(props => (
                        <Checkbox
                          {...props}
                          onChange={e => {
                            setFilter(p => ({
                              ...p,
                              price_type: e.target.checked
                                ? props.value
                                : undefined,
                            }));
                          }}
                          checked={filter?.['price_type'] === props.value}
                        />
                      ))}
                    </Flex>
                  </ContentContainer>

                  <ContentContainer>
                    <SectionWithHeading>Rating</SectionWithHeading>
                    <div>
                      <CustomSelect
                        icon={BlackStar}
                        activeIcon={ColouredStar}
                        config={['5', '4', '3', '2', '1']}
                        onChange={data =>
                          setFilter(p => ({ ...p, star: data }))
                        }
                        value={filter?.['star']}
                      />
                    </div>
                  </ContentContainer>

                  <ContentContainer>
                    <SectionWithHeading>Distance</SectionWithHeading>
                    <div>
                      <CustomSelect
                        config={[
                          { label: 'Within 10 KM', value: '10' },
                          { label: 'Within 20 KM', value: '20' },
                          { label: 'Within 30 KM', value: '30' },
                        ]}
                        onChange={data =>
                          setFilter(p => ({ ...p, distance_range: data }))
                        }
                        value={filter?.['distance_range']}
                      />
                    </div>
                  </ContentContainer>

                  <ContentContainer>
                    <SectionWithHeading>Gender</SectionWithHeading>
                    <div>
                      <RadioGroup
                        config={[
                          {
                            label: 'Male',

                            value: 'Male',
                          },
                          {
                            label: 'Female',

                            value: 'Female',
                          },
                        ]}
                        onChange={data =>
                          setFilter(p => ({ ...p, gender: data }))
                        }
                        value={filter?.['gender']}
                      />
                    </div>
                  </ContentContainer>

                  <Flex row gap={10} justifyEnd>
                    <Button link small onClick={() => clearFilter(close)}>
                      {' '}
                      Clear Filters
                    </Button>
                    <Button small onClick={() => onSubmit(close)}>
                      Apply Filters
                    </Button>
                  </Flex>
                </Flex>
              );
            }}
          />
        </div>
      </Flex>
      <TagContainer>
        <Flex row gap={10}>
          {Object?.entries(filter)
            ?.filter(removeUnwantedKeys)
            ?.map(([key, value], index) => (
              <Tag>
                {key.split('_').join(' ').toUpperCase()}:{' '}
                {value?.label ?? value}
                <img src={CloseSm} onClick={() => removeFilter(key)} alt="" />
              </Tag>
            ))}
        </Flex>
      </TagContainer>
    </Container>
  );
};

export default Filter;
