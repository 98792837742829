import React from 'react';
import {
  Label,
  IconContainer,
  InputContainer,
  StyledTextArea,
} from '../StyledComponents';

const TextArea = ({ label, icon, noMargin, ...restProps }) => {
  const placeholder =
    (restProps.placeholder || '') + (restProps.required ? '*' : '');

  return (
    <InputContainer noMargin={noMargin}>
      {label && <Label>{label}</Label>}
      <StyledTextArea rows={4} {...restProps} placeholder={placeholder} />
      {icon ? (
        <IconContainer>
          <img src={icon} alt="input-icon" />
        </IconContainer>
      ) : null}
    </InputContainer>
  );
};

export default TextArea;
