import { API } from "src/helpers/api";

export const addRate = ({ payload }) => {
  return API.post("/add-ratecard", payload);
};

export const getRate = () => {
  return API.get("/getratecard");
};

export const updateRate = ({ rateId, payload }) => {
  return API.post(`/update-ratecard/${rateId}`, payload);
};

export const deleteRate = ({ rateId }) => {
  return API.get(`/delete-ratecard/${rateId}`);
};
