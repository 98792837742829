import React from 'react';
import About from './About/About';
import Banner from './Banner/Banner';
import Becomeantoiler from './Becomeantoiler/Becomeantoiler';
import Footer from './Footer/Footer';
import Howtohire from './Howtohire/Howtohire';
import Navbar from './Navbar/Navbar';
import States from './States/States';
import Topfeatures from './Topfeatures/Topfeatures';
// import Form from '../Form/Form';
import Form from './Form/Form';

function LandingPage() {
  const [openModal,setopenModal] = React.useState(false);

  const  openPopup = ()=>{
    setopenModal(!openModal);
  }

  return (
    <>
      <Navbar />
      <Banner />
      <About />
      <States />
      <Topfeatures popupisopen={openModal} togglepopup={openPopup} />
      <Howtohire />
      <Becomeantoiler popupisopen={openModal} togglepopup={openPopup} />
      <Footer />
      <Form  popupisopen={openModal} togglepopup={openPopup} />

    </>
  );
}

export default LandingPage;
