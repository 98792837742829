import React, { useContext, useEffect, useState } from 'react';
import RenderSmoothImage from '../RenderSmoothImage';
import {
  AvatarWrapper,
  LightText,
  NameContainer,
  ProfileContainer,
  AvatarBadge,
  AvatarContainer,
  Status,
  CategoryLink,
  LightGrayBackground,
  ProfileWithBg,
  ListDetailsWrapper,
  VerifiedText,
  AddToFav,
  LightTextDiv,
} from './StyledComponents';
import { Flex, HideTextOverflow, HR } from 'src/StyledComponents';
import GreenCircleCheck from 'src/asset/images/green-check-circle.svg';
import ProfileMetric from './ProfileMetric';
import BlackStar from 'src/asset/images/black-star.svg';
import Star from 'src/asset/images/star-white.svg';
import LocationIcon from 'src/asset/images/location-icon.svg';
import Dollar from 'src/asset/images/rupees.svg';
import CallIcon from 'src/asset/images/call.svg';
// import CallRedIcon from 'src/asset/images/call-red.svg';
import ShareIcon from 'src/asset/images/share.svg';
import HeartGrayIcon from 'src/asset/images/heart-gray.svg';
import HeartRedIcon from 'src/asset/images/red-heart.svg';
import CallGrayIcon from 'src/asset/images/call-gray.svg';
import ReportGrayIcon from 'src/asset/images/report-gray.svg';
import ActionIcon from 'src/asset/images/three-dot-black.svg';
import EditIcon from 'src/asset/images/edit-blue.svg';
import VerifiedOrangeIcon from 'src/asset/images/verified-orange.svg';
import Doodle from 'src/asset/images/Doodle.png';
import NoImagePlaceholder from 'src/asset/images/user-filled.svg';
import { Divider } from './StyledComponents';
import { Button, ActionDropdown, ToggleButton } from '..';
import { baseURL } from 'src/helpers/api';
import { numberShortener } from 'src/helpers/util';
import { useNavigate } from 'react-router-dom';
import { addToFav, shareProfile, toggleOnlineStatus } from 'src/services';
import { getUserType, setDialedUser } from 'src/helpers/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'src/firebase';
import { Colors, toastOptions } from 'src/constants';
import { StoreContent } from 'src/helpers/store';
import { toast } from 'react-toastify';
import ReportUser from './ReportUser';

const getAddress = data => {
  return data?.location_area || data?.city
    ? `${
        data.city && data.location_area
          ? `${data.location_area}, `
          : data.location_area
          ? data.location_area
          : ''
      }${data.city || ''}`
    : 'No Address';
};

// const getFile = async (url, name) => {
//   return fetch(url, { mode: 'no-cors' })
//     .then(r => r.blob())
//     .then(blobFile => {
//       console.log({ blobFile });
//       const file = new File([blobFile], name, { type: blobFile.type });
//       alert('Url: ' + url);
//       return file;
//     });
// };

const navigateToToilerProfile = (id, navigate) =>
  navigate(`/antoiler/${id}/reviews`);

const SmallProfileCard = ({ data }) => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [openReport, setOpenReport] = useState(false);

  const {
    user: { 0: loggedUser, 1: setLoggedUser },
  } = useContext(StoreContent);

  const ownProfile = data && user && data.user_id === user.user_id;
  const addressText = getAddress(data);

  const items = [
    ...(!loading && user
      ? [
          {
            key: 'share',
            icon: ShareIcon,
            text: 'Share',
            onClick: async () => {
              let options = {
                url: `https://antoiler.com/antoiler/${data.user_id}/reviews`,
                title: data.first_name,
                text: `${data.first_name}\n${data.main_categories}\n${addressText}\n`,
              };
              // if (data?.profile_pic) {
              //   getFile(baseURL + data?.profile_pic, `${data.first_name}.jpg`)
              //     .then(file => {
              //       console.log(JSON.stringify(file), file);

              //       navigator
              //         .share({ ...options, files: [file] })
              //         .then(() => shareProfile(data.user_id));
              //     })
              //     .catch(e =>
              //       navigator
              //         .share(options)
              //         .then(() => shareProfile(data.user_id))
              //     );
              // } else {
              navigator.share(options).then(() => shareProfile(data.user_id));
              // }
            },
          },
        ]
      : []),
    !ownProfile && {
      key: 'contact',
      icon: CallGrayIcon,
      text: 'Contact',
      onClick: () => navigateToToilerProfile(data.user_id, navigate),
    },
    !ownProfile &&
      loggedUser?.user_id && {
        key: 'report',
        icon: ReportGrayIcon,
        text: 'Report',
        onClick: e => {
          setOpenReport(true);
        },
      },
  ].filter(Boolean);

  const [bool, setBool] = useState(false);
  useEffect(() => {
    if (data) {
      setBool(data.is_liked === 1);
    }
  }, [data, data.is_liked]);

  const toggleFavourite = e => {
    e.stopPropagation();
    addToFav(data?.user_id).then(() => setBool(prev => !prev));
    setLoggedUser({
      ...loggedUser,
      newWishlist: true,
    });
  };

  return (
    <ProfileContainer
      onClick={() => navigateToToilerProfile(data.user_id, navigate)}
      small
      width="calc(50% - 5px)"
      className="profile-container"
    >
      {!ownProfile && loggedUser?.user_id && (
        <AddToFav
          width="25px"
          src={bool ? HeartRedIcon : HeartGrayIcon}
          alt={'add-fav'}
          onClick={toggleFavourite}
        />
      )}

      <ActionDropdown
        styleProps={{
          position: 'absolute',
          top: '5px',
          right: '10px',
          zIndex: 2,
        }}
        triggerPadding
        items={items}
        trigger={props => (
          <img width="15px" src={ActionIcon} alt="action-button" {...props} />
        )}
        id={data?.user_id}
      />
      <AvatarWrapper circle>
        <RenderSmoothImage
          src={baseURL + data?.profile_pic}
          objectFit="cover"
        />
      </AvatarWrapper>
      <NameContainer small>
        <HideTextOverflow
          fontSize="16px"
          width={data?.verified ? 'calc(100% - 20px)' : '100%'}
        >
          {data?.first_name ?? '-'}
        </HideTextOverflow>
        {data?.is_verified === 1 && (
          <img src={GreenCircleCheck} alt="verified" width="15" height="15" />
        )}
      </NameContainer>
      {/* <LightText> */}
      <HideTextOverflow
        width="100%"
        fontWeight="300"
        fontSize="12px"
        alignCenter
      >
        {addressText}
      </HideTextOverflow>
      {/* </LightText> */}
      <HideTextOverflow
        width="100%"
        fontWeight="500"
        fontSize="14px"
        alignCenter
      >
        {data?.main_categories ?? '-'}
      </HideTextOverflow>
      <Flex row centered gap={15} height="45px">
        {!!data.overall_rating ? (
          <ProfileMetric
            colored
            postIcon={BlackStar}
            fontSize={16}
            width={'80px'}
            mainText={data?.overall_rating}
            subText="out of 5"
          />
        ) : data.experience ? (
          <ProfileMetric
            fontSize={14}
            mainText={`${data.experience}`}
            subText="yrs Exp"
          />
        ) : (
          <ProfileMetric
            colored
            postIcon={BlackStar}
            fontSize={16}
            width={'80px'}
            mainText={data?.overall_rating}
            subText="out of 5"
          />
        )}
        <Divider />
        <ProfileMetric
          fontSize={15}
          preIcon={Dollar}
          mainText={numberShortener(data?.user_rate)}
          subText={data?.rate_type}
          width="50%"
        />
      </Flex>
      <ReportUser
        close={() => setOpenReport(false)}
        name={data?.first_name}
        userId={data?.user_id}
        open={openReport}
      />
    </ProfileContainer>
  );
};

const LargeProfileCard = ({ data }) => {
  const {
    user: [user, setUser],
  } = useContext(StoreContent);

  const ownProfile = data && user && data.user_id === user.user_id;

  const openDialer = mobile => {
    if (data?.online_status === 1) {
      setDialedUser(data);
      document.location.href = `tel:${mobile}`;
    } else {
      toast.warn('Antoiler is unavailable', toastOptions);
    }
  };

  const [bool, setBool] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  const addressText = getAddress(data);

  useEffect(() => {
    if (data) {
      setBool(data.is_liked === 1);
    }
  }, [data, data.is_liked]);

  const toggleFavourite = () => {
    addToFav(data?.user_id).then(() => setBool(prev => !prev));
    setUser({
      ...user,
      newWishlist: true,
    });
  };

  const items = [
    {
      key: 'share',
      icon: ShareIcon,
      text: 'Share',
      onClick: async () => {
        let options = {
          url: `https://antoiler.com/antoiler/${data.user_id}/reviews`,
          title: data.first_name,
          text: `${data.first_name}\n${data.main_categories}\n${addressText}\n`,
        };
        // if (data?.profile_pic) {
        //   getFile(baseURL + data?.profile_pic, `${data.first_name}.jpg`)
        //     .then(file => {
        //       console.log(JSON.stringify(file), file);

        //       navigator
        //         .share({ ...options, files: [file] })
        //         .then(() => shareProfile(data.user_id));
        //     })
        //     .catch(e =>
        //       navigator.share(options).then(() => shareProfile(data.user_id))
        //     );
        // } else {
        shareProfile(data.user_id).then(() => navigator.share(options));
        // }
      },
    },
    !ownProfile && {
      key: 'fav',
      icon: bool ? HeartRedIcon : HeartGrayIcon,
      text: `${bool ? 'Added!' : 'Add to Favorites'}`,
      onClick: toggleFavourite,
    },
    !ownProfile && {
      key: 'contact',
      icon: CallGrayIcon,
      text: 'Contact',
      onClick: () => openDialer(data?.mobile || '9876543210'),
    },
    !ownProfile && {
      key: 'report',
      icon: ReportGrayIcon,
      text: 'Report',
      onClick: e => {
        setOpenReport(true);
      },
    },
  ].filter(Boolean);

  return (
    <ProfileWithBg>
      <ActionDropdown
        styleProps={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          zIndex: 3,
        }}
        items={items}
        trigger={props => (
          <img
            style={{ transform: 'rotate(90deg)' }}
            width="15px"
            src={ActionIcon}
            alt="action-button"
            {...props}
          />
        )}
      />
      <LightGrayBackground>
        <RenderSmoothImage src={Doodle} objectFit="cover" />
      </LightGrayBackground>
      <ProfileContainer large>
        <AvatarContainer>
          <AvatarWrapper circle size="150px">
            {data?.profile_pic ? (
              <RenderSmoothImage
                src={baseURL + data?.profile_pic}
                objectFit="cover"
              />
            ) : null}
          </AvatarWrapper>
          {data?.is_verified === 1 && (
            <AvatarBadge>
              <img
                src={GreenCircleCheck}
                alt="verified"
                width="15"
                height="15"
              />
            </AvatarBadge>
          )}
        </AvatarContainer>

        <Flex row centered gap={5} margin="2px">
          <Status available={data?.online_status === 1} />
          <LightText medium>
            {data?.online_status === 1 ? "I'm Available" : "I'm Unavailable"}
          </LightText>
        </Flex>
        <NameContainer>
          <HideTextOverflow fontSize="18px">
            {data?.first_name}
          </HideTextOverflow>
        </NameContainer>
        <LightTextDiv>
          <HideTextOverflow>{addressText}</HideTextOverflow>
        </LightTextDiv>
        <Flex row centered gap={5}>
          <CategoryLink>{data?.main_categories}</CategoryLink>
        </Flex>
        <Flex row centered gap={25} style={{ margin: '10px 0' }}>
          {data.rate_type && data.user_rate >= 0 ? (
            <ProfileMetric
              fontSize={14}
              preIcon={Dollar}
              mainText={numberShortener(data.user_rate)}
              subText={data?.rate_type}
            />
          ) : null}
          <ProfileMetric
            fontSize={14}
            postIcon={BlackStar}
            mainText={data.overall_rating}
            subText="out of 5"
          />
          <ProfileMetric
            fontSize={14}
            mainText={numberShortener(data?.jobs_done || 0)}
            subText="Jobs Done"
          />
          <ProfileMetric
            fontSize={14}
            mainText={numberShortener(data?.shares || 0)}
            subText="Shares"
          />
          {!!data.experience && (
            <ProfileMetric
              fontSize={14}
              mainText={`${data.experience}`}
              subText="yrs Exp"
            />
          )}
        </Flex>
        <Button
          dark
          style={{ padding: '10px 15px' }}
          icon={CallIcon}
          onClick={() => data.mobile && openDialer(data.mobile)}
          disabled={!data.mobile || data?.online_status !== 1 || ownProfile}
        >
          Contact
        </Button>
      </ProfileContainer>
      <ReportUser
        close={() => setOpenReport(false)}
        name={data?.first_name}
        userId={data?.user_id}
        open={openReport}
      />
    </ProfileWithBg>
  );
};

const ListCard = ({ data }) => {
  const navigate = useNavigate();

  const {
    user: [user, setUser],
  } = useContext(StoreContent);

  const ownProfile = data && user && data.user_id === user.user_id;

  const [bool, setBool] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  const addressText = getAddress(data);

  useEffect(() => {
    if (data) {
      setBool(data.is_liked === 1);
    }
  }, [data, data.is_liked]);

  const toggleFavourite = () => {
    addToFav(data?.user_id).then(() => setBool(prev => !prev));
    setUser({
      ...user,
      newWishlist: true,
    });
  };

  const items = [
    {
      key: 'share',
      icon: ShareIcon,
      text: 'Share',
      onClick: async () => {
        let options = {
          url: `https://antoiler.com/antoiler/${data.user_id}/reviews`,
          title: data.first_name,
          text: `${data.first_name}\n${data.main_categories}\n${addressText}\n`,
        };
        // if (data?.profile_pic) {
        //   getFile(baseURL + data?.profile_pic, `${data.first_name}.jpg`)
        //     .then(file => {
        //       console.log(JSON.stringify(file), file);

        //       navigator
        //         .share({ ...options, files: [file] })
        //         .then(() => shareProfile(data.user_id));
        //     })
        //     .catch(e =>
        //       navigator.share(options).then(() => shareProfile(data.user_id))
        //     );
        // } else {
        navigator.share(options).then(() => shareProfile(data.user_id));
        // }
      },
    },
    !ownProfile && {
      key: 'fav',
      icon: bool ? HeartRedIcon : HeartGrayIcon,
      text: `${bool ? 'Added!' : 'Add to Favorites'}`,
      onClick: toggleFavourite,
    },
    {
      key: 'contact',
      icon: CallGrayIcon,
      text: 'Contact',
      onClick: () => navigateToToilerProfile(data.user_id, navigate),
    },
    !ownProfile && {
      key: 'report',
      icon: ReportGrayIcon,
      text: 'Report',
      onClick: e => {
        setOpenReport(true);
      },
    },
  ].filter(Boolean);

  return (
    <ProfileContainer
      row
      list
      width="99%"
      onClick={() => navigateToToilerProfile(data.user_id, navigate)}
    >
      <ActionDropdown
        styleProps={{
          position: 'absolute',
          top: '0.5rem',
          right: '0.5rem',
          zIndex: 2,
        }}
        items={items}
        trigger={props => (
          <img
            style={{ transform: 'rotate(90deg)' }}
            width="15px"
            src={ActionIcon}
            alt="action-button"
            {...props}
          />
        )}
      />
      <AvatarWrapper size="80px">
        <RenderSmoothImage
          src={
            data?.profile_pic ? baseURL + data.profile_pic : NoImagePlaceholder
          }
          objectFit="cover"
        />
      </AvatarWrapper>
      <ListDetailsWrapper>
        <NameContainer alignLeft gap={5}>
          <HideTextOverflow maxWidth="calc(100% - 90px)">
            {data?.first_name}
          </HideTextOverflow>
          <LightText medium>{`(${data?.gender?.[0].toUpperCase()}, ${
            data?.age || 0
          })`}</LightText>
        </NameContainer>
        <HideTextOverflow width="calc(100% - 50px)" fontSize="10px">
          {addressText}
        </HideTextOverflow>
        <CategoryLink orange>{data?.main_categories}</CategoryLink>
        <Flex row leftAlign justifyBetween gap={5} padding="0px 5px 0px 0px">
          <Flex row leftAlign gap={10}>
            <ProfileMetric
              postIcon={BlackStar}
              mainText={data?.overall_rating}
              subText="out of 5"
            />
            <ProfileMetric
              preIcon={Dollar}
              mainText={numberShortener(data?.user_rate)}
              subText={data?.rate_type}
            />
            {data?.shares ? (
              <ProfileMetric
                mainText={numberShortener(data?.shares)}
                subText="Shares"
              />
            ) : (
              <ProfileMetric
                mainText={numberShortener(data?.jobs_done)}
                subText="Jobs Done"
              />
            )}
          </Flex>

          {data?.is_verified === 1 && (
            <div style={{ width: '58px' }}>
              <VerifiedText>
                <img
                  src={GreenCircleCheck}
                  alt="verified"
                  width="8"
                  height="8"
                />
                Verified
              </VerifiedText>
              <LightText tiny>U.Id: {data?.user_id}</LightText>
            </div>
          )}
        </Flex>
      </ListDetailsWrapper>
      <ReportUser
        close={() => setOpenReport(false)}
        name={data?.first_name}
        userId={data?.user_id}
        open={openReport}
      />
    </ProfileContainer>
  );
};

const DashboardCard = ({ data }) => {
  const isSp = getUserType() === 'SP';

  const navigate = useNavigate();

  const {
    // eslint-disable-next-line no-unused-vars
    status: [isOnline, setStatus],
  } = useContext(StoreContent);

  const toggleStatus = () => {
    toggleOnlineStatus().then(res => setStatus(res.data?.status === 1));
  };

  const navigateToEditProfile = () => navigate('/edit');

  return (
    <ProfileContainer list width="99%">
      <Flex row>
        <AvatarWrapper size="80px">
          {data?.profile_pic ? (
            <RenderSmoothImage
              src={baseURL + data?.profile_pic}
              objectFit="cover"
            />
          ) : null}
        </AvatarWrapper>
        <ListDetailsWrapper flex gap={3}>
          <Flex row justofyBetween>
            <NameContainer alignLeft gap={5} width="calc(100% - 45px)">
              <HideTextOverflow maxWidth="calc(100% - 50px)">
                {data?.first_name}
              </HideTextOverflow>
              {isSp && (
                <LightText medium>{`(${data?.gender?.[0].toUpperCase()}, ${
                  data?.age || 0
                })`}</LightText>
              )}
            </NameContainer>
            {isSp && (
              <Flex
                width="max-content"
                row
                gap={0}
                centered
                className="edit-button"
              >
                <Button medium link underlined onClick={navigateToEditProfile}>
                  Edit
                </Button>
              </Flex>
            )}
          </Flex>
          <HideTextOverflow width="calc(100% - 50px)" fontSize="10px">
            {getAddress(data)}
          </HideTextOverflow>
          {isSp ? (
            <>
              <CategoryLink orange>{data?.main_categories}</CategoryLink>
              <Flex row justifyBetween gap={5} padding="0px 5px 0px 0px">
                <Flex row gap={5} alignBaseline>
                  <h3 style={{ fontSize: '18px' }}>₹{data.user_rate}</h3>
                  <LightText medium>{data.rate_type}</LightText>
                </Flex>
                <Flex row gap={5} width="max-content">
                  <p style={{ fontSize: '10px', color: Colors.PRIMARY_ORANGE }}>
                    Availability:{' '}
                  </p>
                  <ToggleButton onChange={toggleStatus} checked={isOnline} />
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex width="max-content" row gap={0}>
              <RenderSmoothImage src={EditIcon} height="10px" width="10px" />
              <Button tiny link underlined onClick={navigateToEditProfile}>
                Edit Your Profile
              </Button>
            </Flex>
          )}
        </ListDetailsWrapper>
      </Flex>
      <HR height={1} />
      <Flex leftAlign width="100%" gap={5} row height="15px">
        <Flex row gap={2} width="max-content">
          <LightText style={{ fontSize: '9px' }}>Joined:</LightText>
          <LightText style={{ fontSize: '9px' }} color={Colors.PRIMARY_ORANGE}>
            {data.joinedDate ?? `21 May, 2020`}
          </LightText>
        </Flex>
        <>
          <Flex row width="max-content" flexEnd>
            .
          </Flex>
          <Flex row gap={2} width="max-content">
            <LightText style={{ fontSize: '9px' }}>Identity:</LightText>
            <Flex row width="max-content" centered gap={2} height="max-content">
              {data.is_verified ? (
                <>
                  <LightText
                    style={{ fontSize: '9px' }}
                    color={Colors.PRIMARY_ORANGE}
                  >
                    Verified
                  </LightText>
                  <RenderSmoothImage
                    src={VerifiedOrangeIcon}
                    width="8px"
                    height="8px"
                    containerStyle={{ display: 'flex' }}
                  />
                </>
              ) : (
                <LightText
                  style={{ fontSize: '9px' }}
                  color={Colors.PRIMARY_ORANGE}
                >
                  {(() => {
                    switch (data?.profile_status) {
                      case 0:
                        return 'Unauthorized';
                      case 2:
                        return 'Published';
                      case 3:
                        return 'Un-Published';
                      case 5:
                        return 'Deactivated';
                      default:
                        return 'Not Verified';
                    }
                  })()}
                </LightText>
              )}
            </Flex>
          </Flex>
        </>
        <Flex row width="max-content" flexEnd>
          .
        </Flex>
        <Flex row gap={2} width="max-content">
          <LightText style={{ fontSize: '9px' }}>Mobile:</LightText>
          <LightText style={{ fontSize: '9px' }} color={Colors.PRIMARY_ORANGE}>
            {data.mobile ? data.mobile : '-'}
          </LightText>
        </Flex>
      </Flex>
    </ProfileContainer>
  );
};

const MinimalCard = ({ data, noMetrics, feedbackRedirect }) => {
  const navigate = useNavigate();

  const [bool, setBool] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  const addressText = getAddress(data);

  useEffect(() => {
    if (data) {
      setBool(data.is_liked === 1);
    }
  }, [data, data.is_liked]);

  const {
    // eslint-disable-next-line no-unused-vars
    user: [user, setUser],
  } = useContext(StoreContent);

  const toggleFavourite = () => {
    addToFav(data?.user_id).then(() => setBool(prev => !prev));
    setUser({
      ...user,
      newWishlist: true,
    });
  };

  const provideFeedback = () => {
    navigate('/review/submit-feedback/' + data.user_id);
  };

  const items = [
    {
      key: 'share',
      icon: ShareIcon,
      text: 'Share',
      onClick: async () => {
        let options = {
          url: `https://antoiler.com/antoiler/${data.user_id}/reviews`,
          title: data.first_name,
          text: `${data.first_name}\n${data.main_categories}\n${addressText}\n`,
        };
        // if (data?.profile_pic) {
        //   getFile(baseURL + data?.profile_pic, `${data.first_name}.jpg`)
        //     .then(file => {
        //       console.log(JSON.stringify(file), file);
        //       navigator
        //         .share({ ...options, files: [file] })
        //         .then(() => shareProfile(data.user_id));
        //     })
        //     .catch(e =>
        //       navigator.share(options).then(() => shareProfile(data.user_id))
        //     );
        // } else {
        navigator.share(options).then(() => shareProfile(data.user_id));
        // }
      },
    },
    {
      key: 'fav',
      icon: bool ? HeartRedIcon : HeartGrayIcon,
      text: `${bool ? 'Added!' : 'Add to Favorites'}`,
      onClick: toggleFavourite,
    },
    {
      key: 'contact',
      icon: CallGrayIcon,
      text: 'Contact',
      onClick: () => navigateToToilerProfile(data.user_id, navigate),
    },
    {
      key: 'report',
      icon: ReportGrayIcon,
      text: 'Report',
      onClick: e => {
        setOpenReport(true);
      },
    },
  ];

  return (
    <ProfileContainer sharp noBg list width="99%">
      <Flex row>
        <ActionDropdown
          styleProps={{
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
            zIndex: 2,
          }}
          items={items}
          trigger={props => (
            <img
              style={{ transform: 'rotate(90deg)' }}
              width="15px"
              src={ActionIcon}
              alt="action-button"
              {...props}
            />
          )}
        />
        <AvatarWrapper
          size={noMetrics ? '70px' : '90px'}
          onClick={() => navigateToToilerProfile(data?.user_id, navigate)}
        >
          {data?.profile_pic ? (
            <RenderSmoothImage
              src={
                data?.profile_pic
                  ? baseURL + data.profile_pic
                  : NoImagePlaceholder
              }
              objectFit="cover"
            />
          ) : (
            <img src={NoImagePlaceholder} alt="placeholder" />
          )}
        </AvatarWrapper>
        <ListDetailsWrapper flex gap={noMetrics ? 7 : 1}>
          <NameContainer alignLeft gap={5}>
            <HideTextOverflow
              maxWidth="calc(100% - 90px)"
              onClick={() => navigateToToilerProfile(data?.user_id, navigate)}
            >
              {data?.first_name}
            </HideTextOverflow>
            <LightText medium>{`(${data?.gender?.[0].toUpperCase()}, ${
              data?.age || 0
            })`}</LightText>
          </NameContainer>
          <Flex row gap={5}>
            <img src={LocationIcon} alt="location" width="8px" />
            <HideTextOverflow width="calc(100% - 50px)" fontSize="10px">
              {addressText}
            </HideTextOverflow>
          </Flex>
          <CategoryLink orange>{data?.main_categories}</CategoryLink>
          {!noMetrics && (
            <Flex
              row
              leftAlign
              justifyBetween
              gap={5}
              padding="0px 5px 0px 0px"
            >
              {feedbackRedirect ? (
                <div
                  style={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: Colors.PRIMARY_ORANGE,
                    borderRadius: '3px',
                    padding: '8px',
                    marginTop: '5px',
                    color: Colors.WHITE,
                  }}
                >
                  <HideTextOverflow
                    width="100%"
                    fontSize="10px"
                    onClick={provideFeedback}
                  >
                    Rate {data?.first_name}
                  </HideTextOverflow>
                  <img
                    src={Star}
                    style={{ width: '8px', height: '8px' }}
                    alt="star"
                  />
                </div>
              ) : (
                <Flex row leftAlign gap={10}>
                  {/* <ProfileMetric
                    colored
                    preIcon={CallRedIcon}
                    onClick={() =>
                      navigateToToilerProfile(data.user_id, navigate)
                    }
                  /> */}
                  <ProfileMetric
                    colored
                    postIcon={BlackStar}
                    fontSize={16}
                    width={'80px'}
                    mainText={data?.overall_rating}
                    subText="out of 5"
                  />
                  <ProfileMetric
                    colored
                    fontSize={16}
                    mainText={'₹' + numberShortener(data?.user_rate)}
                    subText={data?.rate_type}
                    width={'80px'}
                  />
                </Flex>
              )}
            </Flex>
          )}
        </ListDetailsWrapper>
      </Flex>
      {feedbackRedirect && (
        <>
          <HR height={1} />
          <Flex row gap={10}>
            <p style={{ fontSize: '10px' }}>
              Your Precious feedback can reward him with more upcoming jobs.
              Please be honest with yout feedback. Thanks!
            </p>
          </Flex>
        </>
      )}
      <ReportUser
        close={() => setOpenReport(false)}
        name={data?.first_name}
        userId={data?.user_id}
        open={openReport}
      />
    </ProfileContainer>
  );
};

const ProfileCard = ({
  small,
  list,
  minimalList,
  data,
  noMetrics,
  dashboard,
  feedbackRedirect,
  isSp = false,
}) => {
  const getCard = () => {
    if (small) return <SmallProfileCard data={data} />;
    if (list) return <ListCard data={data} />;
    if (minimalList)
      return (
        <MinimalCard
          data={data}
          noMetrics={noMetrics}
          feedbackRedirect={feedbackRedirect}
        />
      );
    if (dashboard) return <DashboardCard data={data} isS={isSp} />;
    return <LargeProfileCard data={data} />;
  };

  return <>{getCard()}</>;
};

export default ProfileCard;
