import React from 'react';
import './Howtohire.css';
// import Howtohireimage from 'src/asset/img/howtohire.png';
import Howtohireimage from 'src/asset/images/Antoiler-App-Mobile-Mockup.png';

import Download from 'src/asset/img/download.png';
import Search from 'src/asset/img/search.png';
// import Register from 'src/asset/img/register.png';
import verifiedtoilers2 from 'src/asset/img/verifiedtoilers2.png';

function Howtohire() {
  return (
    <div className="ant-how-to-hire" id="Howtohire">
      <div className="how-to-left">
        <img src={Howtohireimage} alt="How-To-Hire-Antoiler" />
      </div>
      <div className="how-to-right">
        <div className="how-to-content">
          <p className="how-to-heading">
            How to <span>Hire?</span>
          </p>
          <p className="how-to-subheading">
            Hire Nearby Professional by Using These Three Steps
          </p>
        </div>
        <div className="how-to-points">
          <div className="hire-point">
            <div className="point-icon">
              <img src={Download} alt="Download-antoiler" />
            </div>
            <div className="point-content">
              <p className="point-heading"><a href="https://play.google.com/store/apps/details?id=com.antoiler.twa&pli=1"  target={'_blank'} className="link" >Download</a> or Open App</p>
              <p className="point-description">
                We made the Download Process Super Easy. Open <b>Antoiler </b>
                 on your mobile and get the app installed by
                pressing "Add Antoiler to Home Screen" Voila! You have got the
                App Installed. You can also download the Antoiler app from the
                play store.
              </p>
            </div>
          </div>
          <div className="hire-point">
            <div className="point-icon">
              <img src={Search} alt="search-antoiler" />
            </div>
            <div className="point-content">
              <p className="point-heading">Search for Toiler</p>
              <p className="point-description">
                Once you get the app opened, you will see all the nearby
                professionals on your app screen. These Antoilers are available
                within 5 kilometers of your location. You can search for
                plumbers, electricians, or technicians within your range.
              </p>
            </div>
          </div>
          <div className="hire-point">
            <div className="point-icon">
              <img src={verifiedtoilers2} alt="verified-antoiler" />
            </div>
            <div className="point-content">
              <p className="point-heading">Direct Contact & Hire</p>
              <p className="point-description">
                Antoiler is a way to connect with the Professionals near you,
                where you're free to make the deal with these professionals at
                your ease. We don't charge any commission or payment to provide
                you the service providers, nor from the Users or the Antoilers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Howtohire;
