import React, { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import styledComponents from 'styled-components';
import { Button } from '../components';
import TermsAndConditions from 'src/pages/SignUp/Sp/TermsAndConditions';

const SignUp = lazy(() => import('src/pages/SignUp'));
const TestArea = lazy(() => import('src/pages/TestArea'));
const MobileVerify = lazy(() => import('src/pages/SignUp/User/MobileVerify'));
const SpDetailsRegistration = lazy(() =>
  import('src/pages/SignUp/Sp/UserDetails')
);
const OTPVerify = lazy(() => import('src/pages/SignUp/OTPVerify'));
const UserDetails = lazy(() => import('src/pages/SignUp/User/UserDetails'));
const RateDetails = lazy(() => import('src/pages/SignUp/Sp/RateDetails'));
const ThankYou = lazy(() => import('src/pages/SignUp/Sp/ThankYou'));
const Portfolio = lazy(() => import('src/pages/SignUp/Sp/Portfolio'));
const Homepage = lazy(() => import('src/pages/Homepage'));
const ToilerProfile = lazy(() => import('src/pages/ToilerProfile'));
const ProtectedRoute = lazy(() => import('./ProtectedRoute'));
const LoginRoute = lazy(() => import('./LoginRoute'));
const SearchResultsRoute = lazy(() => import('src/pages/SearchResultPage'));
const FeedbackRoute = lazy(() => import('src/pages/Feedback'));
const Reviews = lazy(() => import('src/pages/Reviews'));
const Dashboard = lazy(() => import('src/pages/Dashboard'));
const MyToilers = lazy(() => import('src/pages/MyToilers'));
const EditProfile = lazy(() => import('src/pages/EditProfile'));

const Routes = () => {
  let routes = useRoutes([
    { path: '/', element: <Homepage /> },
    { path: 'signup', element: <SignUp /> },
    { path: 'user/signup', element: <SignUp /> },
    { path: 'components', element: <TestArea /> },
    { path: 'privacy-policy', element: <TermsAndConditions /> },
    {
      path: 'become-an-antoiler',
      element: <LoginRoute component={SpDetailsRegistration} />,
    },
    {
      path: 'antoiler/rate-details',
      element: <LoginRoute component={RateDetails} />,
    },
    { path: 'thank-you', element: <ThankYou /> },
    {
      path: ':name/workdone',
      element: <LoginRoute component={Portfolio} />,
    },
    {
      path: 'antoiler/portfolio',
      element: <LoginRoute component={Portfolio} />,
    },
    {
      path: 'login',
      element: <LoginRoute component={MobileVerify} />,
    },
    {
      path: 'antoiler/otp-verify',
      element: <LoginRoute component={OTPVerify} />,
    },
    {
      path: 'user-info',
      element: <LoginRoute component={UserDetails} />,
    },
    {
      path: 'antoiler/:id/:tab',
      element: <ProtectedRoute component={ToilerProfile} />,
    },
    {
      path: 'search/:searchText',
      element: <ProtectedRoute component={SearchResultsRoute} />,
    },
    {
      path: 'search',
      element: <ProtectedRoute component={SearchResultsRoute} />,
    },
    {
      path: 'review/submit-feedback/:id',
      element: <ProtectedRoute component={FeedbackRoute} />,
    },
    {
      path: 'dashboard',
      element: <ProtectedRoute component={Dashboard} />,
    },
    {
      path: 'hired',
      element: <ProtectedRoute component={MyToilers} type="hired" />,
    },
    {
      path: 'contacted',
      element: <ProtectedRoute component={MyToilers} type="contacted" />,
    },
    {
      path: 'wishlist',
      element: <ProtectedRoute component={MyToilers} type="wishlist" />,
    },
    {
      path: 'published-feedback',
      element: <ProtectedRoute component={Reviews} />,
    },
    {
      path: 'edit',
      element: <ProtectedRoute component={EditProfile} type="profile" />,
    },
    {
      path: 'edit-workdone',
      element: <ProtectedRoute component={EditProfile} type="workDone" />,
    },
    {
      path: 'edit-ratecard',
      element: <ProtectedRoute component={EditProfile} type="rateCard" />,
    },
    { path: '*', element: <h1>No Route Found</h1> },
  ]);
  return routes;
};

const LoaderContainer = styledComponents.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppRouter = () => {
  return (
    <Suspense
      fallback={
        <LoaderContainer>
          <Button loading link />
        </LoaderContainer>
      }
    >
      <Routes />
    </Suspense>
  );
};

export default AppRouter;
