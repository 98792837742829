import axios from 'axios';
import { API } from 'src/helpers/api';

export const getIpAddress = () => {
  return axios.get('https://api.ipify.org?format=json').then(res => {
    console.log('IP resp => ', res.data);
    return res.data.ip;
  });
};

export const getBrowserName = () => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge';
  } else {
    browserName = 'No browser detection';
  }

  return browserName;
};

export const getOsName = () => {
  let OSName = 'Unknown OS';
  if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
  else if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
  else if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
  else if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';

  return OSName;
};

export const saveDownloadLog = payload => {
  return API.post(`/save-download`, payload);
};
