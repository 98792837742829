import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOut } from 'src/firebase';
import {
  getUserFromStorage,
  removeUserData,
  setUserToStorage,
} from './storage';

export const StoreContent = createContext();

export const ContentProvider = props => {
  const navigate = useNavigate();
  const [user, setUser] = useState(getUserFromStorage());
  const [isOnline, setIsOnline] = useState(user?.online_status === 1);

  const logoutUser = () => {
    return logOut().then(() => {
      setUser({});
      removeUserData();
      navigate('/');
    });
  };

  useEffect(() => {
    setUserToStorage(user);
    setIsOnline(user?.online_status === 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const setStatus = status => {
    setIsOnline(status);
    setUser({ ...user, online_status: status ? 1 : 0 });
  };

  const value = {
    user: [user, setUser, logoutUser],
    status: [isOnline, setStatus],
  };

  return (
    <StoreContent.Provider value={value}>
      {props.children}
    </StoreContent.Provider>
  );
};
