import React from 'react';
import { Flex } from 'src/StyledComponents';
import { Colors } from 'src/constants';
import styled from 'styled-components';
import { StarRateView, RenderSmoothImage, Button } from 'src/components';
import EditIcon from 'src/asset/images/edit-blue.svg';
import { useNavigate } from 'react-router-dom';

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
`;

const AvatarName = styled.h4`
  width: 100%;
  text-align: left;
  font: normal normal bold 16px Poppins;
  font-weight: 600;
  letter-spacing: 0px;
  opacity: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AvatarRating = styled.span`
  font: normal normal bold 10px Poppins;
  letter-spacing: 0px;
  color: ${Colors.PRIMARY_ORANGE};
`;

const TimeStamp = styled.span`
  width: 100%;
  font: normal normal normal 10px Poppins;
  letter-spacing: 0px;
  color: ${Colors.LIGHT_GRAY};
`;

const CommmentText = styled.p`
  width: 100%;
  font: normal normal 400 14px Poppins;
  text-align: ${props => (props.centered ? 'center' : 'left')};
  letter-spacing: 0px;
  color: #8b8b8b;
`;

const Comments = props => {
  const {
    reviewForId,
    avatarName,
    rating,
    avatar,
    timeStamp,
    description,
    editReview,
    reviewId,
    hyperlink,
  } = props;

  const navigate = useNavigate();

  return (
    <Flex gap={10}>
      {description ? (
        <>
          <Flex row gap={10} flexStart>
            <Flex row gap={10}>
              {avatar && (
                <Avatar>
                  <RenderSmoothImage objectFit={'cover'} src={avatar} />
                </Avatar>
              )}
              <Flex width={editReview ? '40vw' : '100vw'}>
                <AvatarName
                  style={
                    hyperlink
                      ? { color: '#006FFF', textDecoration: 'underline' }
                      : {}
                  }
                  onClick={() => {
                    if (hyperlink) navigate(`/antoiler/${reviewForId}/reviews`);
                  }}
                >
                  {avatarName}
                </AvatarName>
                <Flex row gap={10} flexStart>
                  <Flex row alignBaseline gap={5} width="max-content">
                    <AvatarRating>{rating}</AvatarRating>
                    <StarRateView rating={rating} />
                  </Flex>
                  <TimeStamp>{timeStamp}</TimeStamp>
                </Flex>
              </Flex>
            </Flex>

            {editReview ? (
              <Flex width="max-content" row gap={0}>
                <RenderSmoothImage src={EditIcon} height="10px" width="10px" />
                <Button
                  tiny
                  link
                  underlined
                  onClick={() =>
                    navigate(`/review/submit-feedback/${props?.reviewForId}`, {
                      state: { reviewId },
                    })
                  }
                >
                  Edit & Republish
                </Button>
              </Flex>
            ) : null}
          </Flex>
          <CommmentText>{description}</CommmentText>
        </>
      ) : (
        <CommmentText centered>{'No reply'}</CommmentText>
      )}
    </Flex>
  );
};

export default Comments;
