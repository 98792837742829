import { Fonts, Colors } from 'src/constants';
import styled from 'styled-components';

export const ProfileWithBg = styled.div`
  position: relative;
`;

export const ProfileContainer = styled.div`
  position: relative;
  width: ${props => props.width ?? '100%'};
  height: max-content;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: ${props => (props.sharp ? '4px' : '10px')};
  padding: ${props => (props.list ? '6px 8px' : '20px')};
  display: flex;
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  gap: ${props => (props.large ? '0px' : '5px')};
  margin: ${props => (props.small || props.large ? '0px' : '5px 0px')};
  align-items: center;
  background-color: ${props =>
    props.large || props.noBg ? 'white' : Colors.PROFILE_BG};
`;

export const LightGrayBackground = styled.div`
  position: absolute;
  height: 100px;
  background-color: ${Colors.LIGHT_BG_GRAY};
  z-index: 1;
  top: 0;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
`;

export const AvatarWrapper = styled.div`
  border-radius: ${props => (props.circle ? '50%' : '5px')};
  height: ${props => props.size ?? '100px'};
  width: ${props => props.size ?? '100px'};
  overflow: hidden;
  flex-shrink: 0;
`;

export const AvatarContainer = styled.div`
  margin: 5px;
  position: relative;
  z-index: 2;
`;

export const NameContainer = styled.div`
  ${props => !props.small && 'width: 100%;'}
  ${props => props.small && 'max-width: 65%;'}
  ${props => props.width && 'width:' + props.width + ';'}
  display: flex;
  font-weight: 600;
  justify-content: ${props => (props.alignLeft ? 'left' : 'center')};
  align-items: center;
  ${props => (props.gap ? `gap: ${props.gap}px;` : '')}
  gap: 5px;
  ${props => (props.fontSize ? `font-size:${props.fontSize};` : '')}
`;

export const LightText = styled.p`
  font-weight: 300;
  font-family: ${Fonts.POPPINS};
  font-size: ${props => (props.medium ? '10px' : props.tiny ? '8px' : '12px')};
  ${props => (props.color ? `color: ${props.color};` : '')}
`;

export const LightTextDiv = styled.p`
  font-weight: 300;
  font-family: ${Fonts.POPPINS};
  font-size: ${props => (props.medium ? '10px' : props.tiny ? '8px' : '12px')};
`;

export const Divider = styled.div`
  width: 0px;
  height: 30px;
  border-right: 0.5px solid ${Colors.LIGHT_GRAY};
`;

export const AvatarBadge = styled.div`
  width: 15px;
  height: 15px;
  background-color: white;
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 10;
  border-radius: 50%;
`;

export const Status = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => (props.available ? 'orange' : 'gray')};
`;

export const CategoryLink = styled.p`
  color: ${props =>
    props.orange ? Colors.PRIMARY_ORANGE : Colors.LINK_LIGHT_BLUE};
  font-size: 10px;
`;

export const ListDetailsWrapper = styled.div`
  padding-left: 10px;
  width: calc(100% - 80px);
  ${props =>
    props.flex
      ? `
    display: flex;
    flex-direction: column;
    gap: ${props.gap || 5}px;
  `
      : ''}
`;

export const VerifiedText = styled.div`
  color: ${Colors.LIGHT_GREEN};
  font-family: ${Fonts.POPPINS};
  font-size: 8px;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const AddToFav = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  zindex: 2;
  padding: 5px;
`;
