import React, { useEffect, useState } from 'react';
import CloseSm from 'src/asset/images/CloseSm.png';
import ArrowDown from 'src/asset/images/arrow-down.png';
import {
  BottomPopup as BottomPopupWrapper,
  Container,
  DropdownButton,
  DropdownButtonContainer,
  OutsideOverlay,
  PopupCloseBar,
  PopupCloseContainer,
} from './StyledComponents';
import Button from '../Button';

import { Flex, H2 } from 'src/StyledComponents';

import { HideTextOverflow } from 'src/StyledComponents';

const BottomPopupComponent = ({
  title,
  mounted,
  closeStyle,
  setShowPopup,
  children,
  showDone,
  popupHeight,
  closeCallback,
}) => {
  const [style, setStyle] = useState({
    position: 'absolute',
    fontSize: 60,
    bottom: '-71%',
    transition: 'all 1s ease-in-out',
    touchStart: 0,
    touchEnd: 0,
  });
  const [show, setShow] = useState(false);

  useEffect(() => {
    // check for the mounted props
    if (!mounted) {
      // call outro animation when mounted prop is false
      setStyle(prev => ({
        ...prev,
        fontSize: 60,
        position: 'absolute',
        bottom: '-100%',
        transition: 'all 0.5s ease-in-out',
      }));
    } else {
      setShow(true);
      setTimeout(() => {
        // css for mount animation
        setStyle(prev => ({
          fontSize: 60,
          position: 'absolute',
          bottom: '0px',
          transition: 'all 0.5s ease',
        }));
      }, 10); // call the into animation
    }
  }, [mounted]);

  const transitionEnd = () => {
    if (!mounted) {
      // remove the node on transition end when the mounted prop is false
      setShow(false);
    }
  };

  return (
    show && (
      <BottomPopupWrapper
        style={style}
        onTransitionEnd={transitionEnd}
        popupHeight={popupHeight}
      >

        <Flex className='drower-title' style={{justifyContent:'space-between',alignItems:'center',width:'100%',height:'auto',flexDirection:'row' }} 
        >
        <H2>
          {title}
        </H2>
        
        {closeStyle === 'icon' ? (
          <PopupCloseContainer
            onClick={e => {
              setShowPopup(e, false);
              closeCallback && closeCallback();
            }}
          >
            <PopupCloseBar />
          </PopupCloseContainer>
        ) : (
            <img
              src={CloseSm}
              alt="close"
              style={{ padding: '5px', cursor: 'pointer' }}
              onClick={e => {
                setShowPopup(e, false);
                closeCallback && closeCallback();
              }}
            />
        )}
        </Flex>
        <div
          style={{
            width: '100%',
            margin: '10px',
            height: '100%',
            overflow: 'scroll',
          }}
        >
          {children}
        </div>
        {showDone ? (
          <Button onClick={e => setShowPopup(e, false)}>Done</Button>
        ) : null}
      </BottomPopupWrapper>
    )
  );
};

const BottomPopup = ({
  title="Title",
  closeStyle = 'close',
  placeholder = 'Select',
  required,
  bottomPopupContent = <>No Content Passed</>,
  icon = ArrowDown,
  prefixIcon,
  value,
  showOk,
  popupHeight,
  link,
  className,
  ImgStyle,
  textProps = {},
  openByDefault,
  closeCallback,
  dashedBorder,
  bordered,
  trigger: Trigger,
  triggerStyle = {},
}) => {
  value = value ? <b>{value}</b> : '';
  const placeholderText = value || (placeholder || '') + (required ? ' *' : '');
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = (e, show) => {
    e?.preventDefault && e.preventDefault();
    e?.stopPropagation && e.stopPropagation();
    setShowPopup(show);
  };

  useEffect(() => {
    if (openByDefault) setShowPopup(true);
  }, [openByDefault]);

  return (
    <Container style={triggerStyle}>
      <DropdownButtonContainer>
        {Trigger ? (
          <Trigger
            style={triggerStyle}
            onClick={e => togglePopup(e, !showPopup)}
          />
        ) : link ? (
          <Button onClick={e => togglePopup(e, !showPopup)} link>
            <HideTextOverflow>{placeholderText}</HideTextOverflow>
          </Button>
        ) : (
          <DropdownButton
            onClick={e => togglePopup(e, !showPopup)}
            dashedBorder={dashedBorder}
            bordered={bordered}
            padding={textProps.padding}
            fontWeight={textProps.fontWeight}
            rowReversed={prefixIcon}
          >
            <HideTextOverflow>{placeholderText}</HideTextOverflow>
            <img src={icon} alt="down" className={className} style={ImgStyle} />
          </DropdownButton>
        )}
      </DropdownButtonContainer>
      <BottomPopupComponent
        title={title}
        closeStyle={closeStyle}
        setShowPopup={(e, value) => togglePopup(e, value)}
        mounted={showPopup}
        showDone={showOk}
        popupHeight={popupHeight}
        closeCallback={closeCallback}
      >
        {bottomPopupContent(togglePopup)}
      </BottomPopupComponent>
      {showPopup && (
        <OutsideOverlay
          onClick={() => {
            setShowPopup(false);
            closeCallback && closeCallback();
          }}
        />
      )}
    </Container>
  );
};

export default BottomPopup;
