import { UPLOAD_API, API } from 'src/helpers/api';

export const addFeedback = payload => {
  return UPLOAD_API.post('/add-review', payload);
};

export const getReviewById = id => {
  return API.get(`/single-review/${id}`);
};

export const updateReview = (id, payload) => {
  return API.post(`/update-review/${id}`, payload);
};

export const saveReviewReply = payload => {
  return API.post(`/reply-review`, payload);
};

export const deleteFeedbackMedia = id => {
  return API.get(`/delete-portfolio/${id}`);
};
