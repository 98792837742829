import { toast } from 'react-toastify';
import PerHourIcon from 'src/asset/images/per-hour.svg';
import PerDayIcon from 'src/asset/images/per-day.svg';
import PerServiceIcon from 'src/asset/images/per-service.svg';
import PerMonthIcon from 'src/asset/images/per-month.svg';

export const acceptedImageTypes = ['jpg', 'jpeg', 'png', 'svg', 'webp'];
export const acceptedVideoTypes = ['mp4', 'mov', 'webm', 'mkv'];

export const toastOptions = {
  position: toast.POSITION.TOP_CENTER,
  hideProgressBar: true,
};

export const SERVICE_TYPE = [
  { label: 'Per Hour', icon: PerHourIcon, value: 'Per Hour' },
  { label: 'Per Day', icon: PerDayIcon, value: 'Per Day' },
  { label: 'Per Service', icon: PerServiceIcon, value: 'Per Service' },
  { label: 'Per Month', icon: PerMonthIcon, value: 'Per Month' },
];

export const SORTING_TYPE = [
  { label: 'Ascending', icon: PerHourIcon, value: 'ASC' },
  { label: 'Descending', icon: PerDayIcon, value: 'DESC' },
];

export const USER_TYPE = {
  USER: 'USER',
  SP: 'SP',
};
