import { Button, RenderSmoothImage } from 'src/components';
import { Flex } from 'src/StyledComponents';
import NoReviews from 'src/asset/images/ratings.png';
import NoRates from 'src/asset/images/rate-card.svg';

export const dateFormatYYYYMMDD = date => {
  if (!date) return;
  const dateObj = new Date(date);
  return `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
};

export const dateDifference = (day1 = new Date(), day2 = new Date()) => {
  const date1 = new Date(day1);
  const date2 = new Date(day2);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const fileTobase64 = (file, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(file);
};

export const numberShortener = (number, maxPlaces = 1) => {
  number = Number(number);

  let abbr;
  if (number >= 1e12) {
    abbr = 'T';
  } else if (number >= 1e9) {
    abbr = 'B';
  } else if (number >= 1e6) {
    abbr = 'M';
  } else if (number >= 1e3) {
    abbr = 'K';
  } else {
    abbr = '';
  }
  return annotate(number, maxPlaces, abbr);
};

function annotate(number, maxPlaces, abbr) {
  let rounded = 0;
  switch (abbr) {
    case 'T':
      rounded = number / 1e12;
      break;
    case 'B':
      rounded = number / 1e9;
      break;
    case 'M':
      rounded = number / 1e6;
      break;
    case 'K':
      rounded = number / 1e3;
      break;
    default:
      rounded = number;
      break;
  }
  if (maxPlaces !== false) {
    let test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$');
    if (test.test('' + rounded)) {
      rounded = rounded.toFixed(maxPlaces);
    }
  }

  return rounded + abbr;
}

export const getNoDataPlaceholder = type => {
  let src;
  let loading = false;
  switch (type) {
    case 'reviews':
      src = NoReviews;
      break;
    case 'rates':
      src = NoRates;
      break;
    default:
      loading = true;
      break;
  }
  return (
    <Flex fluid centered padding="20px" id="abc">
      {!loading ? (
        <div style={{ width: '200px', height: '200px' }}>
          <RenderSmoothImage
            src={src}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      ) : (
        <Button link loading />
      )}
    </Flex>
  );
};

export const titleCase = text => {
  if (typeof text === 'string') {
    return text?.[0]?.toUpperCase() + text.slice(1).toLowerCase();
  }
  return text;
};
