import styled from 'styled-components';
import { Colors } from 'src/constants';

export const Container = styled.div`
  width: 100%;
  flex: 0;
`;

export const BottomPopup = styled.div`
  position: absolute;
  height: ${props => props.popupHeight || '75%'};
  min-height: 250px;
  width: 100%;
  left: 0;
  bottom: 0;
  border-radius: 30px 30px 0 0;
  padding: 20px;
  padding-top: 30px;
  background-color: ${Colors.BOTTOMPOPUP_BG};
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PopupCloseContainer = styled.div`
  position: absolute;
  top: 1rem;
  ${props => props.align && 'right:10px;'}
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: ${props => (props.align === 'right' ? 'right' : 'center')};
  align-items: center;
`;

export const PopupCloseBar = styled.div`
  width: 30%;
  border-radius: 5px;
  height: 5px;
  background-color: #bcbcbc;
  cursor: pointer;
`;

export const OutsideOverlay = styled.div`
  width: 100vw;
  height: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${Colors.OVERLAY_BG};
`;

export const DropdownButtonContainer = styled.div`
  position: relative;
  width: ${props => props.width ?? '100%'};
  margin: ${props => props.margin ?? '5px 0'};
`;

export const DropdownButton = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px ${props => (props.dashedBorder ? 'dashed' : 'solid')}
    ${Colors.DARK};
  ${props => props.bordered && 'border: 1px solid black; border-radius: 5px;'}
  color: ${Colors.DARK};
  font-size: 14px;
  font-weight: ${props => props.fontWeight ?? 400};
  outline: none;
  padding: ${props => props.padding ?? '10px'};
  display: flex;
  flex-direction: ${props => (props.rowReversed ? 'row-reverse' : 'row')};
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 5px;
`;
