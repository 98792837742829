import React, { Component } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import StarRateIcon from '@mui/icons-material/StarRate';
// import AttachMoney from '@mui/icons-material/AttachMoney';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import Dollar from 'src/asset/img/dollar.png';
import Carpenter from 'src/asset/img/Carpenter.svg';
import Electrician from 'src/asset/img/Electrician.svg';
import Plumber from 'src/asset/img/Plumber.svg';


import './slider.css';
// import { Button } from "@mui/material";

class Card_Carousel extends Component {
  constructor(props) {
    super(props);
    this.page = 0;
    this.changeProffession = props.changeProf;
    this.proff = props.PList;
    this.changeCard = () => {
      this.page = (this.page + 1) % this.proff.length;
      this.changeProffession(this.proff[this.page]);
    };
  }

  static get CARD_STYLE() {
    return {
      height: '250px',
      width: '260px',
      display: 'block',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      background: 'white',
      color: '#FFF',
      fontSize: '12px',
      textTransform: 'uppercase',
      borderRadius: '10px',
      position: 'relative',
      padding: '5px',
      cursore: 'pointer',
    };
  }

  render() {
    return (
      <div className="slider-container">
        <ReactCardCarousel
          autoplay={true}
          spread={'wide'}
          afterChange={this.changeCard}
          autoplay_speed={2500}
        >
          <div className="card" style={Card_Carousel.CARD_STYLE}>
            <img
              className="profilepic"
              src={Carpenter}
              alt="Carpenter Antoiler"
            />
            <div style={{ display: 'block', color: 'black' }}>
              <span className="ant-name">Jasvinder...</span>
              <img
                className="checkmark"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png"
                alt="check"
              />
            </div>
            <div>
              <span className="ant-address">City Center Gwalior, (M.P.)</span>
              <span className="ant-category">{this.proff[this.page]}</span>
              <div className="bottom-rating">
                <div className="rating">
                  4.9
                  <StarRateIcon sx={{ fontSize: '10px' }} />
                  <h6>Out of 5</h6>
                </div>
                <div className="rate">
                  <b>₹</b>30
                  <h6>Per Hour</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="card" style={Card_Carousel.CARD_STYLE}>
            <img
              className="profilepic"
              src={Plumber}
              alt="Electrician Antoiler"
            />
            <div style={{ display: 'block', color: 'black' }}>
              <span className="ant-name">Suresh...</span>
              <img
                className="checkmark"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png"
                alt="check"
              />
            </div>
            <div>
              <span className="ant-address">Gandhi Nagar Gwalior, (M.P.)</span>
              <span className="ant-category">{this.proff[this.page]}</span>
              <div className="bottom-rating">
                <div className="rating">
                  4.9
                  <StarRateIcon sx={{ fontSize: '10px' }} />
                  <h6>Out of 5</h6>
                </div>
                <div className="rate">
                  <b>₹</b>30
                  <h6>Per Hour</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="card" style={Card_Carousel.CARD_STYLE}>
            <img
              className="profilepic"
              src={Electrician}
              alt="Plumber Avatar"
            />
            <div style={{ display: 'block', color: 'black' }}>
              <span className="ant-name">Ramesh...</span>
              <img
                className="checkmark"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png"
                alt="check"
              />
            </div>
            <div>
              <span className="ant-address">Vinay Nagar Gwalior, (M.P.)</span>
              <span className="ant-category">{this.proff[this.page]}</span>
              <div className="bottom-rating">
                <div className="rating">
                  4.9
                  <StarRateIcon sx={{ fontSize: '10px' }} />
                  <h6>Out of 5</h6>
                </div>
                <div className="rate">
                  <b>₹</b>30
                  <h6>Per Hour</h6>
                </div>
              </div>
            </div>
          </div>
        </ReactCardCarousel>
      </div>
    );
  }
}

export default Card_Carousel;
