import React from 'react';
import {
  Label,
  IconContainer,
  InputContainer,
  StyledInput,
} from './StyledComponents';

const Input = ({
  type = 'text',
  label,
  icon,
  prefix = false,
  noMargin = false,
  ...restProps
}) => {
  const placeholder =
    (restProps.placeholder || '') + (restProps.required ? '*' : '');

  return (
    <InputContainer style={restProps.style || {}} noMargin={noMargin}>
      {label && <Label>{label}</Label>}
      <StyledInput
        prefix={prefix}
        {...restProps}
        type={type}
        placeholder={placeholder}
      />
      {icon ? (
        <IconContainer prefix={prefix}>
          <img src={icon} alt="input-icon" />
        </IconContainer>
      ) : null}
    </InputContainer>
  );
};

export default Input;
