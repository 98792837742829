import React from 'react';
import styled from 'styled-components';
import { Colors } from 'src/constants';
import ForwardIcon from 'src/asset/images/Back.svg';
import { toast } from 'react-toastify';
import { toastOptions } from 'src/constants';

const StyledFooter = styled.h4`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${Colors.TERTIARY_ORANGE};
  gap: 1rem;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  span {
    flex: 1 0 auto;
  }
`;

const StyledButton = styled.button`
  padding: 14px 0;
  width: 40%;
  background: ${Colors.PRIMARY_ORANGE};
  border: none;
  font-weight: 600;
  color: #fff;
`;

const Footer = ({ children, onClick = () => {}, disabled, ...props }) => {
  return (
    <StyledFooter {...props}>
      <span>{children}</span>
      <StyledButton
        onClick={e => {
          if (disabled)
            toast.error('Uploading is in progress...', toastOptions);
          else onClick(e);
        }}
      >
        {props.buttonName}{' '}
        <img
          src={ForwardIcon}
          alt="forward"
          style={{
            filter: 'brightness(10)',
            width: '3%',
            transform: 'rotate(180deg)',
            verticalAlign: 'middle',
            marginLeft: '3px',
          }}
        />
      </StyledButton>
    </StyledFooter>
  );
};

export default Footer;
