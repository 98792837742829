import { API } from 'src/helpers/api';

export const updateMobile = ({ payload }) => {
  return API.post('/update-mobile', payload);
};

export const verifyMobile = () => {
  return API.get('/verfy-mobile');
};

export const checkMobileExist = ({ payload }) => {
  return API.post('/check-mobile', payload);
};

export const getProfessionOptions = (search = '') => {
  return API.get(`/search-category?s=${search}`);
};

export const signupServiceProvider = ({ payload, token }) => {
  let url = token ? '/become-a-toiler' : '/signup-troiler';
  return API.post(url, payload);
};

export const toggleOnlineStatus = () => {
  return API.get('/antoiler_on_off');
};

export const login = ({ payload }) => {
  return API.post('/login', payload);
};

export const updateToilerProfile = ({ payload }) => {
  return API.post('/update-toiler-profile', payload);
};

export const saveUserProfile = ({ payload }) => {
  return API.post('/save-user', payload);
};

export const deleteUserProfile = ({ payload }) => {
  return API.get(`/delete_user/${payload}`);
};

export const getToilerDetails = () => {
  return API.get('/toiler-profile');
};

export const updateUserProfile = ({ payload }) => {
  return API.post('/update-user-profile', payload);
};

// Added By Gautam 10-05-2022 For Landing Page
export const SaveBecomeAntoiler = ({ payload }) => {
  return API.post('/save-p-enquery', payload);
};

export const SaveContact = ({ payload }) => {
  return API.post('/contact-enq', payload);
};

export const deactivateAccount = (reason = '') => {
  return API.post('/deactivate-account', { reason });
};
