import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex } from 'src/StyledComponents';
import { Colors } from 'src/constants';

const Container = styled.div`
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemList = styled.ul`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transition: opacity 100ms ease-in;
  opacity: ${props => (props.show ? 1 : 0)};
  position: absolute;
  bottom: ${props =>
    props.placement === 'TOP' ? 'calc(100% + 8px)' : 'unset'};
  top: ${props =>
    props.placement === 'BOTTOM' ? 'calc(100% + 8px)' : 'unset'};
  right: 10px;
  background-color: #f1f1f1;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px;
  border-radius: 10px;
  z-index: 10;
  overflow: hidden;
`;

const Item = styled.li`
  border-bottom: 0.5px solid ${Colors.LIGHT_BG_GRAY};
  list-style-type: none;
  white-space: nowrap;
  padding: ${props => (props.medium ? '15px 20px' : '10px')};
  background: white;
  &:last-child {
    border-bottom: none;
  }
`;

const Icon = styled.img`
  height: 15px;
  width: 15px;
`;

function ActionDropdown({
  trigger: Trigger,
  items = [],
  placement = 'BOTTOM',
  styleProps = {},
  id = Date.now(),
  triggerPadding,
  medium,
}) {
  const [show, setShow] = useState(false);

  const handleDocumentClick = useCallback(
    e => {
      const element = document.getElementById('item-list-' + id);
      const triggerElement = document.getElementById('trigger-' + id);
      if (
        e.target !== element &&
        !element.contains(e.target) &&
        !triggerElement.contains(e.target)
      ) {
        setShow(false);
      }
    },
    [id]
  );

  useEffect(() => {
    if (show) {
      window.addEventListener('click', handleDocumentClick, true);
    } else {
      window.removeEventListener('click', handleDocumentClick, true);
    }
    return () => {
      window.removeEventListener('click', handleDocumentClick, true);
    };
  }, [handleDocumentClick, show]);

  const toggleShow = e => {
    e.stopPropagation();
    setShow(prev => !prev);
  };

  return (
    <Container style={styleProps}>
      <div
        style={{
          padding: triggerPadding ? '5px' : '',
          cursor: 'pointer',
          width: '100%',
        }}
        onClick={toggleShow}
      >
        <Trigger id={`trigger-${id}`} />
      </div>
      <ItemList id={'item-list-' + id} show={show} placement={placement}>
        {items.map((item, i) => {
          return (
            <Item
              key={`item-${id}-${i}`}
              medium={medium}
              onClick={e => {
                e.stopPropagation();
                item?.onClick?.(e);
                if (item?.autoClose ?? true) toggleShow(e);
              }}
            >
              <Flex row gap={10}>
                <Flex row gap={10}>
                  {item.icon && <Icon src={item.icon} />}
                  {item.iconRender && item.iconRender}
                  <span>{item.text}</span>
                </Flex>
                {item?.render?.()}
              </Flex>
            </Item>
          );
        })}
      </ItemList>
    </Container>
  );
}

export default ActionDropdown;
