import React from 'react';
import styled from 'styled-components';
import { Colors } from 'src/constants';
import LeftArrow from 'src/asset/images/arrow-back.png';
import { useNavigate } from 'react-router-dom';

const StyledHeader = styled.h4`
  padding: 14px 20px;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${Colors.TERTIARY_ORANGE};
  gap: 1rem;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  ${props => (props.light ? `font-weight: 400;` : '')}
  ${props => (props.light ? `color: gray;` : '')}
`;

const Image = styled.img``;
// width: 15px;

const Header = ({ children, ...props }) => {
  const navigate = useNavigate();
  return (
    <StyledHeader {...props}>
      <Image
        src={LeftArrow}
        alt="go-back-btn-icon"
        onClick={() => navigate(-1)}
      />
      <span>{children}</span>
    </StyledHeader>
  );
};

export default Header;
