import React, { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import styledComponents from 'styled-components';
import { Button } from '../components';
import TermsAndConditions from 'src/pages/SignUp/Sp/TermsAndConditions';
import LandingPage from 'src/pages/landing-page/LandingPage';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


const SignUp = lazy(() => import('src/pages/SignUp'));
const TestArea = lazy(() => import('src/pages/TestArea'));
const MobileVerify = lazy(() => import('src/pages/SignUp/User/MobileVerify'));
const SpDetailsRegistration = lazy(() =>
import('src/pages/SignUp/Sp/UserDetails')
);




const Routes = () => {
  let routes = useRoutes([
    { path: '/', element: <LandingPage /> },  
    { path: '/privacy-policy', element: <TermsAndConditions /> },
    { path: '/terms-and-conditions', element: <TermsAndConditions /> },  
    { path: '*', element: <h1>No Route Found</h1> },
  ]);
  return routes;
};

const LoaderContainer = styledComponents.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DesktopRoutes = () => {
  return (
    <Suspense
      fallback={
        <LoaderContainer>
          <Button loading link />
        </LoaderContainer>
      }
    >
        <GoogleReCaptchaProvider
    reCaptchaKey="6LetRgwjAAAAACih46y7aTQRd_eX8IZQhvq7_VmM"
    language="en"
    useRecaptchaNet="true"
    useEnterprise="true"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
  >
      <Routes />
  </GoogleReCaptchaProvider>
    </Suspense>
  );
};

export default DesktopRoutes;
