import React, { memo } from 'react';
import './style.css';

const RenderSmoothImage = ({
  src,
  alt = 'notFound',
  objectFit = 'contain',
  containerStyle = {},
  ...restProps
}) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [isValidSrc, setIsValidSrc] = React.useState(!!src);

  return (
    <div className="smooth-image-wrapper" style={containerStyle}>
      {isValidSrc ? (
        <img
          className={`smooth-image img-${imageLoaded ? 'visible' : 'hidden'}`}
          style={{
            objectFit,
            height: restProps.height,
            width: restProps.width,
          }}
          src={src}
          alt={alt}
          onLoad={() => setImageLoaded(true)}
          onError={() => setIsValidSrc(false)}
          {...restProps}
        />
      ) : (
        <div className="smooth-no-image">{alt}</div>
      )}
      {isValidSrc && !imageLoaded && (
        <div className="smooth-preloader">
          <span className="loader" />
        </div>
      )}
    </div>
  );
};

const isSrcEqual = (prevProps, nextProps) => prevProps.src !== nextProps.src;

export default memo(RenderSmoothImage, isSrcEqual);
