import React from 'react';
import styled from 'styled-components';
import CloseSm from 'src/asset/images/CloseSm.png';
import { Colors } from 'src/constants';

const OverLay = styled.div`
  z-index: ${props => props.zIndex || 2};
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background: ${Colors.OVERLAY_BG};
`;

const StyledModal = styled.div`
  padding: 1rem;
  box-shadow: 0px 3px 6px #8585852b;
  border: 0.5px solid #aaaaaa;
  border-radius: 4px;
  background: #ffffff 0% 0% no-repeat padding-box;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-height: 90%;
  height: ${props => props.height || 'auto'};
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid #8585852b;
  height: 40px;
  display: flex;
  justify-content: space-between;
`;

export const CloseIcon = styled.img`
  /* position: absolute; */
  padding: 5px;
  cursor: pointer;
  height: 25px;
  width: 25px;
  right: 10px;
  top: 10px;
`;

const ModalContent = styled.div`
  width: 100%;
  height: calc(100% - 50px);
`;

const Modal = ({ children, onClose, modalHeight, title = '', ...props }) => (
  <OverLay {...props}>
    <StyledModal height={modalHeight}>
      {typeof onClose === 'function' && (
        <ModalHeader>
          <div />
          <h2>{title}</h2>
          <CloseIcon src={CloseSm} onClick={onClose} />
        </ModalHeader>
      )}
      <ModalContent>{children}</ModalContent>
    </StyledModal>
  </OverLay>
);

export default Modal;
